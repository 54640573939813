import cdnFile from '@/helpers/cdnFile.js'
import genderFemaleAvif from '@/assets/img/create-model/gender/female.avif'
import genderFemaleWebp from '@/assets/img/create-model/gender/female.webp'
import genderMaleAvif from '@/assets/img/create-model/gender/male.avif'
import genderMaleWebp from '@/assets/img/create-model/gender/male.webp'
import femaleRealisticAvif from '@/assets/img/create-model/female/styles/realistic.avif'
import femaleRealisticWebp from '@/assets/img/create-model/female/styles/realistic.webp'
import femaleAnimeAvif from '@/assets/img/create-model/female/styles/anime.avif'
import femaleAnimeWebp from '@/assets/img/create-model/female/styles/anime.webp'
import femaleCaucasianAvif from '@/assets/img/create-model/female/ethnicities/caucasian.avif'
import femaleCaucasianWebp from '@/assets/img/create-model/female/ethnicities/caucasian.webp'
import femaleAsianAvif from '@/assets/img/create-model/female/ethnicities/asian.avif'
import femaleAsianWebp from '@/assets/img/create-model/female/ethnicities/asian.webp'
import femaleArabicAvif from '@/assets/img/create-model/female/ethnicities/arabic.avif'
import femaleArabicWebp from '@/assets/img/create-model/female/ethnicities/arabic.webp'
import femaleIndianAvif from '@/assets/img/create-model/female/ethnicities/hindi.avif'
import femaleIndianWebp from '@/assets/img/create-model/female/ethnicities/hindi.webp'
import femaleLatinaAvif from '@/assets/img/create-model/female/ethnicities/latina.avif'
import femaleLatinaWebp from '@/assets/img/create-model/female/ethnicities/latina.webp'
import femaleAfricanAvif from '@/assets/img/create-model/female/ethnicities/african.avif'
import femaleAfricanWebp from '@/assets/img/create-model/female/ethnicities/african.webp'
import femaleTwentiesAvif from '@/assets/img/create-model/female/ages/twenties.avif'
import femaleTwentiesWebp from '@/assets/img/create-model/female/ages/twenties.webp'
import femaleThirtiesAvif from '@/assets/img/create-model/female/ages/thirties.avif'
import femaleThirtiesWebp from '@/assets/img/create-model/female/ages/thirties.webp'
import femaleFortiesAvif from '@/assets/img/create-model/female/ages/fourties.avif'
import femaleFortiesWebp from '@/assets/img/create-model/female/ages/fourties.webp'
import femaleFiftiesAvif from '@/assets/img/create-model/female/ages/fifties.avif'
import femaleFiftiesWebp from '@/assets/img/create-model/female/ages/fifties.webp'
import femaleBlackEyeAvif from '@/assets/img/create-model/female/eye-colors/black.avif'
import femaleBlackEyeWebp from '@/assets/img/create-model/female/eye-colors/black.webp'
import femaleBrownEyeAvif from '@/assets/img/create-model/female/eye-colors/brown.avif'
import femaleBrownEyeWebp from '@/assets/img/create-model/female/eye-colors/brown.webp'
import femaleGrayEyeAvif from '@/assets/img/create-model/female/eye-colors/gray.avif'
import femaleGrayEyeWebp from '@/assets/img/create-model/female/eye-colors/gray.webp'
import femaleBlueEyeAvif from '@/assets/img/create-model/female/eye-colors/blue.avif'
import femaleBlueEyeWebp from '@/assets/img/create-model/female/eye-colors/blue.webp'
import femaleGreenEyeAvif from '@/assets/img/create-model/female/eye-colors/green.avif'
import femaleGreenEyeWebp from '@/assets/img/create-model/female/eye-colors/green.webp'
import femaleStraightHairAvif from '@/assets/img/create-model/female/hair-styles/straight.avif'
import femaleStraightHairWebp from '@/assets/img/create-model/female/hair-styles/straight.webp'
import femaleCurlyHairAvif from '@/assets/img/create-model/female/hair-styles/curly.avif'
import femaleCurlyHairWebp from '@/assets/img/create-model/female/hair-styles/curly.webp'
import femalePonytailHairAvif from '@/assets/img/create-model/female/hair-styles/ponytail.avif'
import femalePonytailHairWebp from '@/assets/img/create-model/female/hair-styles/ponytail.webp'
import femaleBraidsHairAvif from '@/assets/img/create-model/female/hair-styles/braids.avif'
import femaleBraidsHairWebp from '@/assets/img/create-model/female/hair-styles/braids.webp'
import femaleLongHairAvif from '@/assets/img/create-model/female/hair-lengths/long.avif'
import femaleLongHairWebp from '@/assets/img/create-model/female/hair-lengths/long.webp'
import femaleShortHairAvif from '@/assets/img/create-model/female/hair-lengths/short.avif'
import femaleShortHairWebp from '@/assets/img/create-model/female/hair-lengths/short.webp'
import femaleBrunetteHairAvif from '@/assets/img/create-model/female/hair-colors/brunette.avif'
import femaleBrunetteHairWebp from '@/assets/img/create-model/female/hair-colors/brunette.webp'
import femaleBlackHairAvif from '@/assets/img/create-model/female/hair-colors/black.avif'
import femaleBlackHairWebp from '@/assets/img/create-model/female/hair-colors/black.webp'
import femaleBlondeHairAvif from '@/assets/img/create-model/female/hair-colors/blonde.avif'
import femaleBlondeHairWebp from '@/assets/img/create-model/female/hair-colors/blonde.webp'
import femaleGingerHairAvif from '@/assets/img/create-model/female/hair-colors/ginger.avif'
import femaleGingerHairWebp from '@/assets/img/create-model/female/hair-colors/ginger.webp'
import femalePinkHairAvif from '@/assets/img/create-model/female/hair-colors/pink.avif'
import femalePinkHairWebp from '@/assets/img/create-model/female/hair-colors/pink.webp'
import femalePurpleHairAvif from '@/assets/img/create-model/female/hair-colors/purple.avif'
import femalePurpleHairWebp from '@/assets/img/create-model/female/hair-colors/purple.webp'
import femaleSkinnyAvif from '@/assets/img/create-model/female/body-types/skinny.avif'
import femaleSkinnyWebp from '@/assets/img/create-model/female/body-types/skinny.webp'
import femaleSlimAvif from '@/assets/img/create-model/female/body-types/slim.avif'
import femaleSlimWebp from '@/assets/img/create-model/female/body-types/slim.webp'
import femaleAthleticAvif from '@/assets/img/create-model/female/body-types/athletic.avif'
import femaleAthleticWebp from '@/assets/img/create-model/female/body-types/athletic.webp'
import femaleThickAvif from '@/assets/img/create-model/female/body-types/thick.avif'
import femaleThickWebp from '@/assets/img/create-model/female/body-types/thick.webp'
import femaleSmallBreastAvif from '@/assets/img/create-model/female/breast-sizes/small.avif'
import femaleSmallBreastWebp from '@/assets/img/create-model/female/breast-sizes/small.webp'
import femaleAverageBreastAvif from '@/assets/img/create-model/female/breast-sizes/average.avif'
import femaleAverageBreastWebp from '@/assets/img/create-model/female/breast-sizes/average.webp'
import femaleLargeBreastAvif from '@/assets/img/create-model/female/breast-sizes/large.avif'
import femaleLargeBreastWebp from '@/assets/img/create-model/female/breast-sizes/large.webp'
import femaleHugeBreastAvif from '@/assets/img/create-model/female/breast-sizes/huge.avif'
import femaleHugeBreastWebp from '@/assets/img/create-model/female/breast-sizes/huge.webp'
import femaleSmallButtAvif from '@/assets/img/create-model/female/butt-sizes/small.avif'
import femaleSmallButtWebp from '@/assets/img/create-model/female/butt-sizes/small.webp'
import femaleAverageButtAvif from '@/assets/img/create-model/female/butt-sizes/average.avif'
import femaleAverageButtWebp from '@/assets/img/create-model/female/butt-sizes/average.webp'
import femaleLargeButtAvif from '@/assets/img/create-model/female/butt-sizes/large.avif'
import femaleLargeButtWebp from '@/assets/img/create-model/female/butt-sizes/large.webp'
import femaleHugeButtAvif from '@/assets/img/create-model/female/butt-sizes/huge.avif'
import femaleHugeButtWebp from '@/assets/img/create-model/female/breast-sizes/huge.webp'
import heartsIcon from '@/assets/img/create-model/svgs/hearts.svg'
import angelIcon from '@/assets/img/create-model/svgs/angel.svg'
import submissiveIcon from '@/assets/img/create-model/svgs/submissive.svg'
import flameAltIcon from '@/assets/img/create-model/svgs/flame-alt.svg'
import crownIcon from '@/assets/img/create-model/svgs/crown.svg'
import snowflakeIcon from '@/assets/img/create-model/svgs/snowflake.svg'
import flagUsIcon from '@/assets/img/create-model/svgs/us.svg'
import flagGbIcon from '@/assets/img/create-model/svgs/gb.svg'
import flagInIcon from '@/assets/img/create-model/svgs/in.svg'
import flagAfricaIcon from '@/assets/img/create-model/svgs/africa.svg'
import flagAuIcon from '@/assets/img/create-model/svgs/au.svg'
import flagFrIcon from '@/assets/img/create-model/svgs/fr.svg'
import toothIcon from '@/assets/img/create-model/svgs/tooth.svg'
import bookOpenIcon from '@/assets/img/create-model/svgs/book-open.svg'
import brushIcon from '@/assets/img/create-model/svgs/brush.svg'
import starIcon from '@/assets/img/create-model/svgs/star.svg'
import atomIcon from '@/assets/img/create-model/svgs/atom.svg'
import pencilLineIcon from '@/assets/img/create-model/svgs/pencil-line.svg'
import glassesIcon from '@/assets/img/create-model/svgs/glasses.svg'
import flameIcon from '@/assets/img/create-model/svgs/flame.svg'
import scaleIcon from '@/assets/img/create-model/svgs/scale.svg'
import cyclistIcon from '@/assets/img/create-model/svgs/cyclist.svg'
import blogIcon from '@/assets/img/create-model/svgs/blog.svg'
import mountainIcon from '@/assets/img/create-model/svgs/mountain.svg'
import gamingPadIcon from '@/assets/img/create-model/svgs/gaming-pad.svg'
import baloonsIcon from '@/assets/img/create-model/svgs/baloons.svg'
import cameraIcon from '@/assets/img/create-model/svgs/camera.svg'
import handsIcon from '@/assets/img/create-model/svgs/hands.svg'
import carIcon from '@/assets/img/create-model/svgs/car.svg'
import masksIcon from '@/assets/img/create-model/svgs/masks.svg'
import femaleAnimeRealisticAvif from '@/assets/img/create-model/female/styles/realistic.avif'
import femaleAnimeRealisticWebp from '@/assets/img/create-model/female/styles/realistic.webp'
import femaleAnimeAnimeAvif from '@/assets/img/create-model/female/styles/anime.avif'
import femaleAnimeAnimeWebp from '@/assets/img/create-model/female/styles/anime.webp'
import femaleAnimeWhiteAvif from '@/assets/img/create-model/female-anime/ethnicities/white.avif'
import femaleAnimeWhiteWebp from '@/assets/img/create-model/female-anime/ethnicities/white.webp'
import femaleAnimeBrownAvif from '@/assets/img/create-model/female-anime/ethnicities/brown.avif'
import femaleAnimeBrownWebp from '@/assets/img/create-model/female-anime/ethnicities/brown.webp'
import femaleAnimeBlackAvif from '@/assets/img/create-model/female-anime/ethnicities/black.avif'
import femaleAnimeBlackWebp from '@/assets/img/create-model/female-anime/ethnicities/black.webp'
import femaleAnimeTwentiesAvif from '@/assets/img/create-model/female-anime/ages/twenties.avif'
import femaleAnimeTwentiesWebp from '@/assets/img/create-model/female-anime/ages/twenties.webp'
import femaleAnimeThirtiesAvif from '@/assets/img/create-model/female-anime/ages/thirties.avif'
import femaleAnimeThirtiesWebp from '@/assets/img/create-model/female-anime/ages/thirties.webp'
import femaleAnimeFortiesAvif from '@/assets/img/create-model/female-anime/ages/fourties.avif'
import femaleAnimeFortiesWebp from '@/assets/img/create-model/female-anime/ages/fourties.webp'
import femaleAnimeFiftiesAvif from '@/assets/img/create-model/female-anime/ages/fifties.avif'
import femaleAnimeFiftiesWebp from '@/assets/img/create-model/female-anime/ages/fifties.webp'
import femaleAnimeBlackEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/black.avif'
import femaleAnimeBlackEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/black.webp'
import femaleAnimeBrownEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/brown.avif'
import femaleAnimeBrownEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/brown.webp'
import femaleAnimeGrayEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/gray.avif'
import femaleAnimeGrayEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/gray.webp'
import femaleAnimeBlueEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/blue.avif'
import femaleAnimeBlueEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/blue.webp'
import femaleAnimeGreenEyeAvif from '@/assets/img/create-model/female-anime/eye-colors/green.avif'
import femaleAnimeGreenEyeWebp from '@/assets/img/create-model/female-anime/eye-colors/green.webp'
import femaleAnimeStraightHairAvif from '@/assets/img/create-model/female-anime/hair-styles/straight.avif'
import femaleAnimeStraightHairWebp from '@/assets/img/create-model/female-anime/hair-styles/straight.webp'
import femaleAnimeCurlyHairAvif from '@/assets/img/create-model/female-anime/hair-styles/curly.avif'
import femaleAnimeCurlyHairWebp from '@/assets/img/create-model/female-anime/hair-styles/curly.webp'
import femaleAnimePonytailHairAvif from '@/assets/img/create-model/female-anime/hair-styles/ponytail.avif'
import femaleAnimePonytailHairWebp from '@/assets/img/create-model/female-anime/hair-styles/ponytail.webp'
import femaleAnimeBraidsHairAvif from '@/assets/img/create-model/female-anime/hair-styles/braids.avif'
import femaleAnimeBraidsHairWebp from '@/assets/img/create-model/female-anime/hair-styles/braids.webp'
import femaleAnimeLongHairAvif from '@/assets/img/create-model/female-anime/hair-lengths/long.avif'
import femaleAnimeLongHairWebp from '@/assets/img/create-model/female-anime/hair-lengths/long.webp'
import femaleAnimeShortHairAvif from '@/assets/img/create-model/female-anime/hair-lengths/short.avif'
import femaleAnimeShortHairWebp from '@/assets/img/create-model/female-anime/hair-lengths/short.webp'
import femaleAnimeBrunetteHairAvif from '@/assets/img/create-model/female-anime/hair-colors/brunette.avif'
import femaleAnimeBrunetteHairWebp from '@/assets/img/create-model/female-anime/hair-colors/brunette.webp'
import femaleAnimeBlackHairAvif from '@/assets/img/create-model/female-anime/hair-colors/black.avif'
import femaleAnimeBlackHairWebp from '@/assets/img/create-model/female-anime/hair-colors/black.webp'
import femaleAnimeBlondeHairAvif from '@/assets/img/create-model/female-anime/hair-colors/blonde.avif'
import femaleAnimeBlondeHairWebp from '@/assets/img/create-model/female-anime/hair-colors/blonde.webp'
import femaleAnimeGingerHairAvif from '@/assets/img/create-model/female-anime/hair-colors/ginger.avif'
import femaleAnimeGingerHairWebp from '@/assets/img/create-model/female-anime/hair-colors/ginger.webp'
import femaleAnimePinkHairAvif from '@/assets/img/create-model/female-anime/hair-colors/pink.avif'
import femaleAnimePinkHairWebp from '@/assets/img/create-model/female-anime/hair-colors/pink.webp'
import femaleAnimePurpleHairAvif from '@/assets/img/create-model/female-anime/hair-colors/purple.avif'
import femaleAnimePurpleHairWebp from '@/assets/img/create-model/female-anime/hair-colors/purple.webp'
import femaleAnimeSkinnyAvif from '@/assets/img/create-model/female-anime/body-types/skinny.avif'
import femaleAnimeSkinnyWebp from '@/assets/img/create-model/female-anime/body-types/skinny.webp'
import femaleAnimeSlimAvif from '@/assets/img/create-model/female-anime/body-types/slim.avif'
import femaleAnimeSlimWebp from '@/assets/img/create-model/female-anime/body-types/slim.webp'
import femaleAnimeAthleticAvif from '@/assets/img/create-model/female-anime/body-types/athletic.avif'
import femaleAnimeAthleticWebp from '@/assets/img/create-model/female-anime/body-types/athletic.webp'
import femaleAnimeThickAvif from '@/assets/img/create-model/female-anime/body-types/thick.avif'
import femaleAnimeThickWebp from '@/assets/img/create-model/female-anime/body-types/thick.webp'
import femaleAnimeSmallBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/small.avif'
import femaleAnimeSmallBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/small.webp'
import femaleAnimeAverageBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/average.avif'
import femaleAnimeAverageBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/average.webp'
import femaleAnimeLargeBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/large.avif'
import femaleAnimeLargeBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/large.webp'
import femaleAnimeHugeBreastAvif from '@/assets/img/create-model/female-anime/breast-sizes/huge.avif'
import femaleAnimeHugeBreastWebp from '@/assets/img/create-model/female-anime/breast-sizes/huge.webp'
import femaleAnimeSmallButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/small.avif'
import femaleAnimeSmallButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/small.webp'
import femaleAnimeAverageButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/average.avif'
import femaleAnimeAverageButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/average.webp'
import femaleAnimeLargeButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/large.avif'
import femaleAnimeLargeButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/large.webp'
import femaleAnimeHugeButtAvif from '@/assets/img/create-model/female-anime/butt-sizes/huge.avif'
import femaleAnimeHugeButtWebp from '@/assets/img/create-model/female-anime/butt-sizes/huge.webp'
import maleRealisticAvif from '@/assets/img/create-model/male/styles/realistic.avif'
import maleRealisticWebp from '@/assets/img/create-model/male/styles/realistic.webp'
import maleAnimeAvif from '@/assets/img/create-model/male/styles/anime.avif'
import maleAnimeWebp from '@/assets/img/create-model/male/styles/anime.webp'
import maleCaucasianAvif from '@/assets/img/create-model/male/ethnicities/caucasian.avif'
import maleCaucasianWebp from '@/assets/img/create-model/male/ethnicities/caucasian.webp'
import maleAsianAvif from '@/assets/img/create-model/male/ethnicities/asian.avif'
import maleAsianWebp from '@/assets/img/create-model/male/ethnicities/asian.webp'
import maleArabicAvif from '@/assets/img/create-model/male/ethnicities/arabic.avif'
import maleArabicWebp from '@/assets/img/create-model/male/ethnicities/arabic.webp'
import maleIndianAvif from '@/assets/img/create-model/male/ethnicities/hindi.avif'
import maleIndianWebp from '@/assets/img/create-model/male/ethnicities/hindi.webp'
import maleLatinoAvif from '@/assets/img/create-model/male/ethnicities/latino.avif'
import maleLatinoWebp from '@/assets/img/create-model/male/ethnicities/latino.webp'
import maleAfricanAvif from '@/assets/img/create-model/male/ethnicities/african.avif'
import maleAfricanWebp from '@/assets/img/create-model/male/ethnicities/african.webp'
import maleTwentiesAvif from '@/assets/img/create-model/male/ages/twenties.avif'
import maleTwentiesWebp from '@/assets/img/create-model/male/ages/twenties.webp'
import maleThirtiesAvif from '@/assets/img/create-model/male/ages/thirties.avif'
import maleThirtiesWebp from '@/assets/img/create-model/male/ages/thirties.webp'
import maleFortiesAvif from '@/assets/img/create-model/male/ages/forties.avif'
import maleFortiesWebp from '@/assets/img/create-model/male/ages/forties.webp'
import maleFiftiesAvif from '@/assets/img/create-model/male/ages/fifties.avif'
import maleFiftiesWebp from '@/assets/img/create-model/male/ages/fifties.webp'
import maleBlackEyeAvif from '@/assets/img/create-model/male/eye-colors/black.avif'
import maleBlackEyeWebp from '@/assets/img/create-model/male/eye-colors/black.webp'
import maleBrownEyeAvif from '@/assets/img/create-model/male/eye-colors/brown.avif'
import maleBrownEyeWebp from '@/assets/img/create-model/male/eye-colors/brown.webp'
import maleGrayEyeAvif from '@/assets/img/create-model/male/eye-colors/gray.avif'
import maleGrayEyeWebp from '@/assets/img/create-model/male/eye-colors/gray.webp'
import maleBlueEyeAvif from '@/assets/img/create-model/male/eye-colors/blue.avif'
import maleBlueEyeWebp from '@/assets/img/create-model/male/eye-colors/blue.webp'
import maleGreenEyeAvif from '@/assets/img/create-model/male/eye-colors/green.avif'
import maleGreenEyeWebp from '@/assets/img/create-model/male/eye-colors/green.webp'
import maleStraightHairAvif from '@/assets/img/create-model/male/hair-styles/straight.avif'
import maleStraightHairWebp from '@/assets/img/create-model/male/hair-styles/straight.webp'
import maleCurlyHairAvif from '@/assets/img/create-model/male/hair-styles/curly.avif'
import maleCurlyHairWebp from '@/assets/img/create-model/male/hair-styles/curly.webp'
import maleDreadlocksHairAvif from '@/assets/img/create-model/male/hair-styles/dreadlocks.avif'
import maleDreadlocksHairWebp from '@/assets/img/create-model/male/hair-styles/dreadlocks.webp'
import maleBuzzcutHairAvif from '@/assets/img/create-model/male/hair-styles/buzzCut.avif'
import maleBuzzcutHairWebp from '@/assets/img/create-model/male/hair-styles/buzzCut.webp'
import maleBrownHairAvif from '@/assets/img/create-model/male/hair-colors/brown.avif'
import maleBrownHairWebp from '@/assets/img/create-model/male/hair-colors/brown.webp'
import maleBlackHairAvif from '@/assets/img/create-model/male/hair-colors/black.avif'
import maleBlackHairWebp from '@/assets/img/create-model/male/hair-colors/black.webp'
import maleBlondeHairAvif from '@/assets/img/create-model/male/hair-colors/blonde.avif'
import maleBlondeHairWebp from '@/assets/img/create-model/male/hair-colors/blonde.webp'
import maleGingerHairAvif from '@/assets/img/create-model/male/hair-colors/ginger.avif'
import maleGingerHairWebp from '@/assets/img/create-model/male/hair-colors/ginger.webp'
import maleMuscularAvif from '@/assets/img/create-model/male/body-types/muscular.avif'
import maleMuscularWebp from '@/assets/img/create-model/male/body-types/muscular.webp'
import maleSlimAvif from '@/assets/img/create-model/male/body-types/slim.avif'
import maleSlimWebp from '@/assets/img/create-model/male/body-types/slim.webp'
import caringIcon from '@/assets/img/create-model/svgs/caring.svg'
import protectiveIcon from '@/assets/img/create-model/svgs/protective.svg'
import badBoyIcon from '@/assets/img/create-model/svgs/bad-boy.svg'
import maleAnimeRealisticAvif from '@/assets/img/create-model/male/styles/realistic.avif'
import maleAnimeRealisticWebp from '@/assets/img/create-model/male/styles/realistic.webp'
import maleAnimeAnimeAvif from '@/assets/img/create-model/male/styles/anime.avif'
import maleAnimeAnimeWebp from '@/assets/img/create-model/male/styles/anime.webp'
import maleAnimeWhiteAvif from '@/assets/img/create-model/male-anime/ethnicities/white.avif'
import maleAnimeWhiteWebp from '@/assets/img/create-model/male-anime/ethnicities/white.webp'
import maleAnimeBrownAvif from '@/assets/img/create-model/male/styles/anime.avif'
import maleAnimeBrownWebp from '@/assets/img/create-model/male/styles/anime.webp'
import maleAnimeBlackAvif from '@/assets/img/create-model/male-anime/ethnicities/brown.avif'
import maleAnimeBlackWebp from '@/assets/img/create-model/male-anime/ethnicities/brown.webp'
import maleAnimeTwentiesAvif from '@/assets/img/create-model/male-anime/ages/twenties.avif'
import maleAnimeTwentiesWebp from '@/assets/img/create-model/male-anime/ages/twenties.webp'
import maleAnimeThirtiesAvif from '@/assets/img/create-model/male-anime/ages/thirties.avif'
import maleAnimeThirtiesWebp from '@/assets/img/create-model/male-anime/ages/thirties.webp'
import maleAnimeFortiesAvif from '@/assets/img/create-model/male-anime/ages/fourties.avif'
import maleAnimeFortiesWebp from '@/assets/img/create-model/male-anime/ages/fourties.webp'
import maleAnimeFiftiesAvif from '@/assets/img/create-model/male-anime/ages/fifties.avif'
import maleAnimeFiftiesWebp from '@/assets/img/create-model/male-anime/ages/fifties.webp'
import maleAnimeBlackEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/black.avif'
import maleAnimeBlackEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/black.webp'
import maleAnimeBrownEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/brown.avif'
import maleAnimeBrownEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/brown.webp'
import maleAnimeGrayEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/gray.avif'
import maleAnimeGrayEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/gray.webp'
import maleAnimeBlueEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/blue.avif'
import maleAnimeBlueEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/blue.webp'
import maleAnimeGreenEyeAvif from '@/assets/img/create-model/male-anime/eye-colors/green.avif'
import maleAnimeGreenEyeWebp from '@/assets/img/create-model/male-anime/eye-colors/green.webp'
import maleAnimeStraightHairAvif from '@/assets/img/create-model/male-anime/hair-styles/straight.avif'
import maleAnimeStraightHairWebp from '@/assets/img/create-model/male-anime/hair-styles/straight.webp'
import maleAnimeCurlyHairAvif from '@/assets/img/create-model/male-anime/hair-styles/curly.avif'
import maleAnimeCurlyHairWebp from '@/assets/img/create-model/male-anime/hair-styles/curly.webp'
import maleAnimeDreadlocksHairAvif from '@/assets/img/create-model/male-anime/hair-styles/dreadlocks.avif'
import maleAnimeDreadlocksHairWebp from '@/assets/img/create-model/male-anime/hair-styles/dreadlocks.webp'
import maleAnimeBuzzcutHairAvif from '@/assets/img/create-model/male-anime/hair-styles/buzzCut.avif'
import maleAnimeBuzzcutHairWebp from '@/assets/img/create-model/male-anime/hair-styles/buzzCut.webp'
import maleAnimeBrownHairAvif from '@/assets/img/create-model/male-anime/hair-colors/brown.avif'
import maleAnimeBrownHairWebp from '@/assets/img/create-model/male-anime/hair-colors/brown.webp'
import maleAnimeBlackHairAvif from '@/assets/img/create-model/male-anime/hair-colors/black.avif'
import maleAnimeBlackHairWebp from '@/assets/img/create-model/male-anime/hair-colors/black.webp'
import maleAnimeBlondeHairAvif from '@/assets/img/create-model/male-anime/hair-colors/blonde.avif'
import maleAnimeBlondeHairWebp from '@/assets/img/create-model/male-anime/hair-colors/blonde.webp'
import maleAnimeGingerHairAvif from '@/assets/img/create-model/male-anime/hair-colors/ginger.avif'
import maleAnimeGingerHairWebp from '@/assets/img/create-model/male-anime/hair-colors/ginger.webp'
import maleAnimeMuscularAvif from '@/assets/img/create-model/male-anime/body-types/muscular.avif'
import maleAnimeMuscularWebp from '@/assets/img/create-model/male-anime/body-types/muscular.webp'
import maleAnimeSlimAvif from '@/assets/img/create-model/male-anime/body-types/slim.avif'
import maleAnimeSlimWebp from '@/assets/img/create-model/male-anime/body-types/slim.webp'

export const stepsGender = [
  {
    displayName: 'Gender',
    modifies: 'gender',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        displayName: 'Girlfriend',
        value: 'female',
        image: {
          avif: genderFemaleAvif,
          webp: genderFemaleWebp,
        },
      },
      {
        displayName: 'Boyfriend',
        value: 'male',
        image: {
          avif: genderMaleAvif,
          webp: genderMaleWebp,
        },
      },
    ],
  },
]
export const stepsFemaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        displayName: 'Realistic',
        value: 'realistic',
        image: {
          avif: femaleRealisticAvif,
          webp: femaleRealisticWebp,
        },
      },
      {
        displayName: 'Anime',
        value: 'anime',
        image: {
          avif: femaleAnimeAvif,
          webp: femaleAnimeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Caucasian',
        value: 'caucasian',
        image: {
          avif: femaleCaucasianAvif,
          webp: femaleCaucasianWebp,
        },
      },
      {
        displayName: 'Asian',
        value: 'asian',
        image: {
          avif: femaleAsianAvif,
          webp: femaleAsianWebp,
        },
      },
      {
        displayName: 'Arabic',
        value: 'arabic',
        image: {
          avif: femaleArabicAvif,
          webp: femaleArabicWebp,
        },
      },
      {
        displayName: 'Indian',
        value: 'indian',
        image: {
          avif: femaleIndianAvif,
          webp: femaleIndianWebp,
        },
      },
      {
        displayName: 'Latina',
        value: 'latina',
        image: {
          avif: femaleLatinaAvif,
          webp: femaleLatinaWebp,
        },
      },
      {
        displayName: 'African',
        value: 'african',
        image: {
          avif: femaleAfricanAvif,
          webp: femaleAfricanWebp,
        },
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Twenties',
        value: 20,
        image: {
          avif: femaleTwentiesAvif,
          webp: femaleTwentiesWebp,
        },
      },
      {
        displayName: 'Thirties',
        value: 30,
        image: {
          avif: femaleThirtiesAvif,
          webp: femaleThirtiesWebp,
        },
      },
      {
        displayName: 'Fourties',
        value: 40,
        image: {
          avif: femaleFortiesAvif,
          webp: femaleFortiesWebp,
        },
      },
      {
        displayName: 'Fifties',
        value: 50,
        image: {
          avif: femaleFiftiesAvif,
          webp: femaleFiftiesWebp,
        },
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: femaleBlackEyeAvif,
          webp: femaleBlackEyeWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: femaleBrownEyeAvif,
          webp: femaleBrownEyeWebp,
        },
      },
      {
        displayName: 'Gray',
        value: 'gray',
        image: {
          avif: femaleGrayEyeAvif,
          webp: femaleGrayEyeWebp,
        },
      },
      {
        displayName: 'Blue',
        value: 'blue',
        image: {
          avif: femaleBlueEyeAvif,
          webp: femaleBlueEyeWebp,
        },
      },
      {
        displayName: 'Green',
        value: 'green',
        image: {
          avif: femaleGreenEyeAvif,
          webp: femaleGreenEyeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Straight',
        value: 'straight',
        image: {
          avif: femaleStraightHairAvif,
          webp: femaleStraightHairWebp,
        },
      },
      {
        displayName: 'Curly',
        value: 'curly',
        image: {
          avif: femaleCurlyHairAvif,
          webp: femaleCurlyHairWebp,
        },
      },
      {
        displayName: 'Ponytail',
        value: 'ponytail',
        image: {
          avif: femalePonytailHairAvif,
          webp: femalePonytailHairWebp,
        },
      },
      {
        displayName: 'Braids',
        value: 'braids',
        image: {
          avif: femaleBraidsHairAvif,
          webp: femaleBraidsHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Long',
        value: 'long',
        image: {
          avif: femaleLongHairAvif,
          webp: femaleLongHairWebp,
        },
      },
      {
        displayName: 'Short',
        value: 'short',
        image: {
          avif: femaleShortHairAvif,
          webp: femaleShortHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Brunette',
        value: 'brunette',
        image: {
          avif: femaleBrunetteHairAvif,
          webp: femaleBrunetteHairWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: femaleBlackHairAvif,
          webp: femaleBlackHairWebp,
        },
      },
      {
        displayName: 'Blonde',
        value: 'blonde',
        image: {
          avif: femaleBlondeHairAvif,
          webp: femaleBlondeHairWebp,
        },
      },
      {
        displayName: 'Ginger',
        value: 'ginger',
        image: {
          avif: femaleGingerHairAvif,
          webp: femaleGingerHairWebp,
        },
      },
      {
        displayName: 'Pink',
        value: 'pink',
        image: {
          avif: femalePinkHairAvif,
          webp: femalePinkHairWebp,
        },
      },
      {
        displayName: 'Purple',
        value: 'purple',
        image: {
          avif: femalePurpleHairAvif,
          webp: femalePurpleHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Skinny',
        value: 'skinny',
        image: {
          avif: femaleSkinnyAvif,
          webp: femaleSkinnyWebp,
        },
      },
      {
        displayName: 'Slim',
        value: 'slim',
        image: {
          avif: femaleSlimAvif,
          webp: femaleSlimWebp,
        },
      },
      {
        displayName: 'Athletic',
        value: 'athletic',
        image: {
          avif: femaleAthleticAvif,
          webp: femaleAthleticWebp,
        },
      },
      {
        displayName: 'Thick',
        value: 'thick',
        image: {
          avif: femaleThickAvif,
          webp: femaleThickWebp,
        },
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Small',
        value: 'small',
        image: {
          avif: femaleSmallBreastAvif,
          webp: femaleSmallBreastWebp,
        },
      },
      {
        displayName: 'Average',
        value: 'average',
        image: {
          avif: femaleAverageBreastAvif,
          webp: femaleAverageBreastWebp,
        },
      },
      {
        displayName: 'Large',
        value: 'large',
        image: {
          avif: femaleLargeBreastAvif,
          webp: femaleLargeBreastWebp,
        },
      },
      {
        displayName: 'Huge',
        value: 'huge',
        image: {
          avif: femaleHugeBreastAvif,
          webp: femaleHugeBreastWebp,
        },
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Small',
        value: 'small',
        image: {
          avif: femaleSmallButtAvif,
          webp: femaleSmallButtWebp,
        },
      },
      {
        displayName: 'Average',
        value: 'average',
        image: {
          avif: femaleAverageButtAvif,
          webp: femaleAverageButtWebp,
        },
      },
      {
        displayName: 'Large',
        value: 'large',
        image: {
          avif: femaleLargeButtAvif,
          webp: femaleLargeButtWebp,
        },
      },
      {
        displayName: 'Huge',
        value: 'huge',
        image: {
          avif: femaleHugeButtAvif,
          webp: femaleHugeButtWebp,
        },
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
        icon: heartsIcon,
      },
      {
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
        icon: angelIcon,
      },
      {
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
        icon: submissiveIcon,
      },
      {
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
        icon: flameAltIcon,
      },
      {
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
        icon: crownIcon,
      },
      {
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
        icon: snowflakeIcon,
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        value: 'new-us-young-1',
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-1',
        displayName: 'American 1',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
      },
      {
        value: 'new-us-young-2',
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-2',
        displayName: 'American 2',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
      },
      {
        value: 'new-us-young-3',
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-3',
        displayName: 'American 3',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
      },
      {
        value: 'new-us-young-4',
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-4',
        displayName: 'American 4',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
      },
      {
        value: 'new-us-young-5',
        displayName: 'American 5',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-5.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-5',
        displayName: 'American 5',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-5.mp3', true),
      },
      {
        value: 'new-us-young-6',
        displayName: 'American 6',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-6.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-6',
        displayName: 'American 6',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-6.mp3', true),
      },
      {
        value: 'new-us-young-7',
        displayName: 'American 7',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-7.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-7',
        displayName: 'American 7',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-7.mp3', true),
      },
      {
        value: 'new-us-young-8',
        displayName: 'American 8',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-8.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-8',
        displayName: 'American 8',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latina',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-8.mp3', true),
      },
      {
        value: 'new-gb-young',
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
      },
      {
        value: 'new-gb-old',
        displayName: 'British',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
      },
      {
        value: 'new-in-young',
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
        icon: flagInIcon,
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
      },
      {
        value: 'new-in-old',
        displayName: 'Indian',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
        icon: flagInIcon,
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
      },
      {
        value: 'new-africa-young',
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
        icon: flagAfricaIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
      },
      {
        value: 'new-africa-old',
        displayName: 'African',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
        icon: flagAfricaIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
      },
      {
        value: 'new-au-young',
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
      },
      {
        value: 'new-au-old',
        displayName: 'Australian',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
      },
      {
        value: 'new-fr',
        displayName: 'French',
        ethnicities: ['caucasian', 'african'],
        startAge: 0,
        endAge: 80,
        icon: flagFrIcon,
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Dentist',
        value: 'dentist',
        icon: toothIcon,
      },
      {
        displayName: 'Librarian',
        value: 'librarian',
        icon: bookOpenIcon,
      },
      {
        displayName: 'Designer',
        value: 'designer',
        icon: brushIcon,
      },
      {
        displayName: 'Stylist',
        value: 'stylist',
        icon: starIcon,
      },
      {
        displayName: 'Scientist',
        value: 'scientist',
        icon: atomIcon,
      },
      {
        displayName: 'Writer',
        value: 'writer',
        icon: pencilLineIcon,
      },
      {
        displayName: 'Teacher',
        value: 'teacher',
        icon: glassesIcon,
      },
      {
        displayName: 'Firefighter',
        value: 'firefighter',
        icon: flameIcon,
      },
      {
        displayName: 'Lawyer',
        value: 'lawyer',
        icon: scaleIcon,
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Cycling',
        value: 'cycling',
        icon: cyclistIcon,
      },
      {
        displayName: 'Blogging',
        value: 'blogging',
        icon: blogIcon,
      },
      {
        displayName: 'Hiking',
        value: 'hiking',
        icon: mountainIcon,
      },
      {
        displayName: 'Gaming',
        value: 'gaming',
        icon: gamingPadIcon,
      },
      {
        displayName: 'Partying',
        value: 'partying',
        icon: baloonsIcon,
      },
      {
        displayName: 'Photography',
        value: 'photography',
        icon: cameraIcon,
      },
      {
        displayName: 'Volunteering',
        value: 'volunteering',
        icon: handsIcon,
      },
      {
        displayName: 'Cars',
        value: 'cars',
        icon: carIcon,
      },
      {
        displayName: 'Theater',
        value: 'theater',
        icon: masksIcon,
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]
export const stepsFemaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        displayName: 'Realistic',
        value: 'realistic',
        image: {
          avif: femaleAnimeRealisticAvif,
          webp: femaleAnimeRealisticWebp,
        },
      },
      {
        displayName: 'Anime',
        value: 'anime',
        image: {
          avif: femaleAnimeAnimeAvif,
          webp: femaleAnimeAnimeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'White',
        value: 'white',
        image: {
          avif: femaleAnimeWhiteAvif,
          webp: femaleAnimeWhiteWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: femaleAnimeBrownAvif,
          webp: femaleAnimeBrownWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: femaleAnimeBlackAvif,
          webp: femaleAnimeBlackWebp,
        },
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Twenties',
        value: 20,
        image: {
          avif: femaleAnimeTwentiesAvif,
          webp: femaleAnimeTwentiesWebp,
        },
      },
      {
        displayName: 'Thirties',
        value: 30,
        image: {
          avif: femaleAnimeThirtiesAvif,
          webp: femaleAnimeThirtiesWebp,
        },
      },
      {
        displayName: 'Fourties',
        value: 40,
        image: {
          avif: femaleAnimeFortiesAvif,
          webp: femaleAnimeFortiesWebp,
        },
      },
      {
        displayName: 'Fifties',
        value: 50,
        image: {
          avif: femaleAnimeFiftiesAvif,
          webp: femaleAnimeFiftiesWebp,
        },
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: femaleAnimeBlackEyeAvif,
          webp: femaleAnimeBlackEyeWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: femaleAnimeBrownEyeAvif,
          webp: femaleAnimeBrownEyeWebp,
        },
      },
      {
        displayName: 'Gray',
        value: 'gray',
        image: {
          avif: femaleAnimeGrayEyeAvif,
          webp: femaleAnimeGrayEyeWebp,
        },
      },
      {
        displayName: 'Blue',
        value: 'blue',
        image: {
          avif: femaleAnimeBlueEyeAvif,
          webp: femaleAnimeBlueEyeWebp,
        },
      },
      {
        displayName: 'Green',
        value: 'green',
        image: {
          avif: femaleAnimeGreenEyeAvif,
          webp: femaleAnimeGreenEyeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Straight',
        value: 'straight',
        image: {
          avif: femaleAnimeStraightHairAvif,
          webp: femaleAnimeStraightHairWebp,
        },
      },
      {
        displayName: 'Curly',
        value: 'curly',
        image: {
          avif: femaleAnimeCurlyHairAvif,
          webp: femaleAnimeCurlyHairWebp,
        },
      },
      {
        displayName: 'Ponytail',
        value: 'ponytail',
        image: {
          avif: femaleAnimePonytailHairAvif,
          webp: femaleAnimePonytailHairWebp,
        },
      },
      {
        displayName: 'Braids',
        value: 'braids',
        image: {
          avif: femaleAnimeBraidsHairAvif,
          webp: femaleAnimeBraidsHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Length',
    modifies: 'hairLength',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Long',
        value: 'long',
        image: {
          avif: femaleAnimeLongHairAvif,
          webp: femaleAnimeLongHairWebp,
        },
      },
      {
        displayName: 'Short',
        value: 'short',
        image: {
          avif: femaleAnimeShortHairAvif,
          webp: femaleAnimeShortHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Brunette',
        value: 'brunette',
        image: {
          avif: femaleAnimeBrunetteHairAvif,
          webp: femaleAnimeBrunetteHairWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: femaleAnimeBlackHairAvif,
          webp: femaleAnimeBlackHairWebp,
        },
      },
      {
        displayName: 'Blonde',
        value: 'blonde',
        image: {
          avif: femaleAnimeBlondeHairAvif,
          webp: femaleAnimeBlondeHairWebp,
        },
      },
      {
        displayName: 'Ginger',
        value: 'ginger',
        image: {
          avif: femaleAnimeGingerHairAvif,
          webp: femaleAnimeGingerHairWebp,
        },
      },
      {
        displayName: 'Pink',
        value: 'pink',
        image: {
          avif: femaleAnimePinkHairAvif,
          webp: femaleAnimePinkHairWebp,
        },
      },
      {
        displayName: 'Purple',
        value: 'purple',
        image: {
          avif: femaleAnimePurpleHairAvif,
          webp: femaleAnimePurpleHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Skinny',
        value: 'skinny',
        image: {
          avif: femaleAnimeSkinnyAvif,
          webp: femaleAnimeSkinnyWebp,
        },
      },
      {
        displayName: 'Slim',
        value: 'slim',
        image: {
          avif: femaleAnimeSlimAvif,
          webp: femaleAnimeSlimWebp,
        },
      },
      {
        displayName: 'Athletic',
        value: 'athletic',
        image: {
          avif: femaleAnimeAthleticAvif,
          webp: femaleAnimeAthleticWebp,
        },
      },
      {
        displayName: 'Thick',
        value: 'thick',
        image: {
          avif: femaleAnimeThickAvif,
          webp: femaleAnimeThickWebp,
        },
      },
    ],
  },
  {
    displayName: 'Breast Size',
    modifies: 'breastSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Small',
        value: 'small',
        image: {
          avif: femaleAnimeSmallBreastAvif,
          webp: femaleAnimeSmallBreastWebp,
        },
      },
      {
        displayName: 'Average',
        value: 'average',
        image: {
          avif: femaleAnimeAverageBreastAvif,
          webp: femaleAnimeAverageBreastWebp,
        },
      },
      {
        displayName: 'Large',
        value: 'large',
        image: {
          avif: femaleAnimeLargeBreastAvif,
          webp: femaleAnimeLargeBreastWebp,
        },
      },
      {
        displayName: 'Huge',
        value: 'huge',
        image: {
          avif: femaleAnimeHugeBreastAvif,
          webp: femaleAnimeHugeBreastWebp,
        },
      },
    ],
  },
  {
    displayName: 'Butt Size',
    modifies: 'buttSize',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Small',
        value: 'small',
        image: {
          avif: femaleAnimeSmallButtAvif,
          webp: femaleAnimeSmallButtWebp,
        },
      },
      {
        displayName: 'Average',
        value: 'average',
        image: {
          avif: femaleAnimeAverageButtAvif,
          webp: femaleAnimeAverageButtWebp,
        },
      },
      {
        displayName: 'Large',
        value: 'large',
        image: {
          avif: femaleAnimeLargeButtAvif,
          webp: femaleAnimeLargeButtWebp,
        },
      },
      {
        displayName: 'Huge',
        value: 'huge',
        image: {
          avif: femaleAnimeHugeButtAvif,
          webp: femaleAnimeHugeButtWebp,
        },
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 3,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
        icon: heartsIcon,
      },
      {
        displayName: 'Innocent',
        displayDescription: 'Optimistic, naive, and sees world with wonder.',
        value: 'innocent',
        icon: angelIcon,
      },
      {
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
        icon: submissiveIcon,
      },
      {
        displayName: 'Nympho',
        displayDescription:
          'Insatiable, passionate, and constantly craving intimacy.',
        value: 'nympho',
        icon: flameAltIcon,
      },
      {
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
        icon: crownIcon,
      },
      {
        displayName: 'Mean',
        displayDescription: 'Cold, dismissive, and often sarcastic.',
        value: 'mean',
        icon: snowflakeIcon,
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        value: 'new-us-young-1',
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-1.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-1',
        displayName: 'American 1',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-1.mp3', true),
      },
      {
        value: 'new-us-young-2',
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-2.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-2',
        displayName: 'American 2',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-2.mp3', true),
      },
      {
        value: 'new-us-young-3',
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-3.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-3',
        displayName: 'American 3',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-3.mp3', true),
      },
      {
        value: 'new-us-young-4',
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-4.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-4',
        displayName: 'American 4',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-4.mp3', true),
      },
      {
        value: 'new-us-young-5',
        displayName: 'American 5',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-5.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-5',
        displayName: 'American 5',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-5.mp3', true),
      },
      {
        value: 'new-us-young-6',
        displayName: 'American 6',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-6.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-6',
        displayName: 'American 6',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-6.mp3', true),
      },
      {
        value: 'new-us-young-7',
        displayName: 'American 7',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-7.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-7',
        displayName: 'American 7',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-7.mp3', true),
      },
      {
        value: 'new-us-young-8',
        displayName: 'American 8',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-us-young-8.mp3',
          true,
        ),
      },
      {
        value: 'new-us-old-8',
        displayName: 'American 8',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/female/voices/new-us-old-8.mp3', true),
      },
      {
        value: 'new-gb-young',
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/female/voices/new-gb-young.mp3', true),
      },
      {
        value: 'new-gb-old',
        displayName: 'British',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/female/voices/new-gb-old.mp3', true),
      },
      {
        value: 'new-in-young',
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
        icon: flagInIcon,
        preview: cdnFile('model-creator/female/voices/new-in-young.mp3', true),
      },
      {
        value: 'new-in-old',
        displayName: 'Indian',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
        icon: flagInIcon,
        preview: cdnFile('model-creator/female/voices/new-in-old.mp3', true),
      },
      {
        value: 'new-africa-young',
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
        icon: flagAfricaIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-africa-young.mp3',
          true,
        ),
      },
      {
        value: 'new-africa-old',
        displayName: 'African',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
        icon: flagAfricaIcon,
        preview: cdnFile(
          'model-creator/female/voices/new-africa-old.mp3',
          true,
        ),
      },
      {
        value: 'new-au-young',
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/female/voices/new-au-young.mp3', true),
      },
      {
        value: 'new-au-old',
        displayName: 'Australian',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/female/voices/new-au-old.mp3', true),
      },
      {
        value: 'new-fr',
        displayName: 'French',
        ethnicities: ['white', 'black'],
        startAge: 0,
        endAge: 80,
        icon: flagFrIcon,
        preview: cdnFile('model-creator/female/voices/new-fr.mp3', true),
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    customModifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Dentist',
        value: 'dentist',
        icon: toothIcon,
      },
      {
        displayName: 'Librarian',
        value: 'librarian',
        icon: bookOpenIcon,
      },
      {
        displayName: 'Designer',
        value: 'designer',
        icon: brushIcon,
      },
      {
        displayName: 'Stylist',
        value: 'stylist',
        icon: starIcon,
      },
      {
        displayName: 'Scientist',
        value: 'scientist',
        icon: atomIcon,
      },
      {
        displayName: 'Writer',
        value: 'writer',
        icon: pencilLineIcon,
      },
      {
        displayName: 'Teacher',
        value: 'teacher',
        icon: glassesIcon,
      },
      {
        displayName: 'Firefighter',
        value: 'firefighter',
        icon: flameIcon,
      },
      {
        displayName: 'Lawyer',
        value: 'lawyer',
        icon: scaleIcon,
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    customModifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Cycling',
        value: 'cycling',
        icon: cyclistIcon,
      },
      {
        displayName: 'Blogging',
        value: 'blogging',
        icon: blogIcon,
      },
      {
        displayName: 'Hiking',
        value: 'hiking',
        icon: mountainIcon,
      },
      {
        displayName: 'Gaming',
        value: 'gaming',
        icon: gamingPadIcon,
      },
      {
        displayName: 'Partying',
        value: 'partying',
        icon: baloonsIcon,
      },
      {
        displayName: 'Photography',
        value: 'photography',
        icon: cameraIcon,
      },
      {
        displayName: 'Volunteering',
        value: 'volunteering',
        icon: handsIcon,
      },
      {
        displayName: 'Cars',
        value: 'cars',
        icon: carIcon,
      },
      {
        displayName: 'Theater',
        value: 'theater',
        icon: masksIcon,
      },
    ],
  },
  {
    displayName: 'Type a girl name...',
    displayTitle: 'Name Your Girlfriend',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]
export const stepsMaleRealistic = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        displayName: 'Realistic',
        value: 'realistic',
        image: {
          avif: maleRealisticAvif,
          webp: maleRealisticWebp,
        },
      },
      {
        displayName: 'Anime',
        value: 'anime',
        image: {
          avif: maleAnimeAvif,
          webp: maleAnimeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Caucasian',
        value: 'caucasian',
        image: {
          avif: maleCaucasianAvif,
          webp: maleCaucasianWebp,
        },
      },
      {
        displayName: 'Asian',
        value: 'asian',
        image: {
          avif: maleAsianAvif,
          webp: maleAsianWebp,
        },
      },
      {
        displayName: 'Arabic',
        value: 'arabic',
        image: {
          avif: maleArabicAvif,
          webp: maleArabicWebp,
        },
      },
      {
        displayName: 'Indian',
        value: 'indian',
        image: {
          avif: maleIndianAvif,
          webp: maleIndianWebp,
        },
      },
      {
        displayName: 'Latino',
        value: 'latino',
        image: {
          avif: maleLatinoAvif,
          webp: maleLatinoWebp,
        },
      },
      {
        displayName: 'African',
        value: 'african',
        image: {
          avif: maleAfricanAvif,
          webp: maleAfricanWebp,
        },
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Twenties',
        value: 20,
        image: {
          avif: maleTwentiesAvif,
          webp: maleTwentiesWebp,
        },
      },
      {
        displayName: 'Thirties',
        value: 30,
        image: {
          avif: maleThirtiesAvif,
          webp: maleThirtiesWebp,
        },
      },
      {
        displayName: 'Fourties',
        value: 40,
        image: {
          avif: maleFortiesAvif,
          webp: maleFortiesWebp,
        },
      },
      {
        displayName: 'Fifties',
        value: 50,
        image: {
          avif: maleFiftiesAvif,
          webp: maleFiftiesWebp,
        },
      },
    ],
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: maleBlackEyeAvif,
          webp: maleBlackEyeWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: maleBrownEyeAvif,
          webp: maleBrownEyeWebp,
        },
      },
      {
        displayName: 'Gray',
        value: 'gray',
        image: {
          avif: maleGrayEyeAvif,
          webp: maleGrayEyeWebp,
        },
      },
      {
        displayName: 'Blue',
        value: 'blue',
        image: {
          avif: maleBlueEyeAvif,
          webp: maleBlueEyeWebp,
        },
      },
      {
        displayName: 'Green',
        value: 'green',
        image: {
          avif: maleGreenEyeAvif,
          webp: maleGreenEyeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Straight',
        value: 'straight',
        image: {
          avif: maleStraightHairAvif,
          webp: maleStraightHairWebp,
        },
      },
      {
        displayName: 'Curly',
        value: 'curly',
        image: {
          avif: maleCurlyHairAvif,
          webp: maleCurlyHairWebp,
        },
      },
      {
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
        image: {
          avif: maleDreadlocksHairAvif,
          webp: maleDreadlocksHairWebp,
        },
      },
      {
        displayName: 'Buzz Cut',
        value: 'buzz cut',
        image: {
          avif: maleBuzzcutHairAvif,
          webp: maleBuzzcutHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: maleBrownHairAvif,
          webp: maleBrownHairWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: maleBlackHairAvif,
          webp: maleBlackHairWebp,
        },
      },
      {
        displayName: 'Blonde',
        value: 'blonde',
        image: {
          avif: maleBlondeHairAvif,
          webp: maleBlondeHairWebp,
        },
      },
      {
        displayName: 'Ginger',
        value: 'ginger',
        image: {
          avif: maleGingerHairAvif,
          webp: maleGingerHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Muscular',
        value: 'muscular',
        image: {
          avif: maleMuscularAvif,
          webp: maleMuscularWebp,
        },
      },
      {
        displayName: 'Slim',
        value: 'slim',
        image: {
          avif: maleSlimAvif,
          webp: maleSlimWebp,
        },
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
        icon: caringIcon,
      },
      {
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
        icon: crownIcon,
      },
      {
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
        icon: submissiveIcon,
      },
      {
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
        icon: heartsIcon,
      },
      {
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
        icon: protectiveIcon,
      },
      {
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
        icon: badBoyIcon,
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        displayName: 'American 1',
        value: 'male-us',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 18,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
      },
      {
        displayName: 'American 2',
        value: 'male-us-young',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us-young.mp3', true),
      },
      {
        displayName: 'American 2',
        value: 'male-us-old',
        ethnicities: [
          'caucasian',
          'asian',
          'arabic',
          'indian',
          'latino',
          'african',
        ],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
      },
      {
        displayName: 'British',
        value: 'male-gb-young',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 0,
        endAge: 34,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
      },
      {
        displayName: 'British',
        value: 'male-gb-old',
        ethnicities: ['caucasian', 'arabic', 'indian', 'african'],
        startAge: 35,
        endAge: 80,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
      },
      {
        displayName: 'Australian',
        value: 'male-au-young',
        ethnicities: ['caucasian', 'asian'],
        startAge: 0,
        endAge: 34,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
      },
      {
        displayName: 'Australian',
        value: 'male-au-old',
        ethnicities: ['caucasian', 'asian'],
        startAge: 35,
        endAge: 80,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
      },
      {
        displayName: 'Indian',
        value: 'male-id-young',
        ethnicities: ['indian'],
        startAge: 0,
        endAge: 34,
        icon: flagInIcon,
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
      },
      {
        displayName: 'Indian',
        value: 'male-id-old',
        ethnicities: ['indian'],
        startAge: 35,
        endAge: 80,
        icon: flagInIcon,
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
      },
      {
        displayName: 'African',
        value: 'male-africa-young',
        ethnicities: ['african'],
        startAge: 0,
        endAge: 34,
        icon: flagAfricaIcon,
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
      },
      {
        displayName: 'African',
        value: 'male-africa-old',
        ethnicities: ['african'],
        startAge: 35,
        endAge: 80,
        icon: flagAfricaIcon,
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Dentist',
        value: 'dentist',
        icon: toothIcon,
      },
      {
        displayName: 'Librarian',
        value: 'librarian',
        icon: bookOpenIcon,
      },
      {
        displayName: 'Designer',
        value: 'designer',
        icon: brushIcon,
      },
      {
        displayName: 'Stylist',
        value: 'stylist',
        icon: starIcon,
      },
      {
        displayName: 'Scientist',
        value: 'scientist',
        icon: atomIcon,
      },
      {
        displayName: 'Writer',
        value: 'writer',
        icon: pencilLineIcon,
      },
      {
        displayName: 'Teacher',
        value: 'teacher',
        icon: glassesIcon,
      },
      {
        displayName: 'Firefighter',
        value: 'firefighter',
        icon: flameIcon,
      },
      {
        displayName: 'Lawyer',
        value: 'lawyer',
        icon: scaleIcon,
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Cycling',
        value: 'cycling',
        icon: cyclistIcon,
      },
      {
        displayName: 'Blogging',
        value: 'blogging',
        icon: blogIcon,
      },
      {
        displayName: 'Hiking',
        value: 'hiking',
        icon: mountainIcon,
      },
      {
        displayName: 'Gaming',
        value: 'gaming',
        icon: gamingPadIcon,
      },
      {
        displayName: 'Partying',
        value: 'partying',
        icon: baloonsIcon,
      },
      {
        displayName: 'Photography',
        value: 'photography',
        icon: cameraIcon,
      },
      {
        displayName: 'Volunteering',
        value: 'volunteering',
        icon: handsIcon,
      },
      {
        displayName: 'Cars',
        value: 'cars',
        icon: carIcon,
      },
      {
        displayName: 'Theater',
        value: 'theater',
        icon: masksIcon,
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]
export const stepsMaleAnime = [
  {
    displayName: 'Style',
    modifies: 'style',
    columns: 2,
    type: 'image-radio',
    options: [
      {
        displayName: 'Realistic',
        value: 'realistic',
        image: {
          avif: maleAnimeRealisticAvif,
          webp: maleAnimeRealisticWebp,
        },
      },
      {
        displayName: 'Anime',
        value: 'anime',
        image: {
          avif: maleAnimeAnimeAvif,
          webp: maleAnimeAnimeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Ethnicity',
    modifies: 'ethnicity',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'White',
        value: 'white',
        image: {
          avif: maleAnimeWhiteAvif,
          webp: maleAnimeWhiteWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: maleAnimeBrownAvif,
          webp: maleAnimeBrownWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: maleAnimeBlackAvif,
          webp: maleAnimeBlackWebp,
        },
      },
    ],
  },
  {
    displayName: 'Age',
    modifies: 'age',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Twenties',
        value: 20,
        image: {
          avif: maleAnimeTwentiesAvif,
          webp: maleAnimeTwentiesWebp,
        },
      },
      {
        displayName: 'Thirties',
        value: 30,
        image: {
          avif: maleAnimeThirtiesAvif,
          webp: maleAnimeThirtiesWebp,
        },
      },
      {
        displayName: 'Fourties',
        value: 40,
        image: {
          avif: maleAnimeFortiesAvif,
          webp: maleAnimeFortiesWebp,
        },
      },
      {
        displayName: 'Fifties',
        value: 50,
        image: {
          avif: maleAnimeFiftiesAvif,
          webp: maleAnimeFiftiesWebp,
        },
      },
    ],
    sidenote: 'The age setting will not impact how your anime will look like.',
  },
  {
    displayName: 'Eye Color',
    modifies: 'eyeColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: maleAnimeBlackEyeAvif,
          webp: maleAnimeBlackEyeWebp,
        },
      },
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: maleAnimeBrownEyeAvif,
          webp: maleAnimeBrownEyeWebp,
        },
      },
      {
        displayName: 'Gray',
        value: 'gray',
        image: {
          avif: maleAnimeGrayEyeAvif,
          webp: maleAnimeGrayEyeWebp,
        },
      },
      {
        displayName: 'Blue',
        value: 'blue',
        image: {
          avif: maleAnimeBlueEyeAvif,
          webp: maleAnimeBlueEyeWebp,
        },
      },
      {
        displayName: 'Green',
        value: 'green',
        image: {
          avif: maleAnimeGreenEyeAvif,
          webp: maleAnimeGreenEyeWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Style',
    modifies: 'hairStyle',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Straight',
        value: 'straight',
        image: {
          avif: maleAnimeStraightHairAvif,
          webp: maleAnimeStraightHairWebp,
        },
      },
      {
        displayName: 'Curly',
        value: 'curly',
        image: {
          avif: maleAnimeCurlyHairAvif,
          webp: maleAnimeCurlyHairWebp,
        },
      },
      {
        displayName: 'Dreadlocks',
        value: 'dreadlocks',
        image: {
          avif: maleAnimeDreadlocksHairAvif,
          webp: maleAnimeDreadlocksHairWebp,
        },
      },
      {
        displayName: 'Buzz Cut',
        value: 'buzz cut',
        image: {
          avif: maleAnimeBuzzcutHairAvif,
          webp: maleAnimeBuzzcutHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Hair Color',
    modifies: 'hairColor',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Brown',
        value: 'brown',
        image: {
          avif: maleAnimeBrownHairAvif,
          webp: maleAnimeBrownHairWebp,
        },
      },
      {
        displayName: 'Black',
        value: 'black',
        image: {
          avif: maleAnimeBlackHairAvif,
          webp: maleAnimeBlackHairWebp,
        },
      },
      {
        displayName: 'Blonde',
        value: 'blonde',
        image: {
          avif: maleAnimeBlondeHairAvif,
          webp: maleAnimeBlondeHairWebp,
        },
      },
      {
        displayName: 'Ginger',
        value: 'ginger',
        image: {
          avif: maleAnimeGingerHairAvif,
          webp: maleAnimeGingerHairWebp,
        },
      },
    ],
  },
  {
    displayName: 'Body Type',
    modifies: 'bodyType',
    columns: 4,
    type: 'image-radio',
    options: [
      {
        displayName: 'Muscular',
        value: 'muscular',
        image: {
          avif: maleAnimeMuscularAvif,
          webp: maleAnimeMuscularWebp,
        },
      },
      {
        displayName: 'Slim',
        value: 'slim',
        image: {
          avif: maleAnimeSlimAvif,
          webp: maleAnimeSlimWebp,
        },
      },
    ],
  },
  {
    displayName: 'Personality',
    modifies: 'personality',
    customModifies: 'customPersonality',
    isCustom: true,
    columns: 2,
    type: 'icon-radio',
    allowEmpty: true,
    allowNull: false,
    options: [
      {
        displayName: 'Caring',
        displayDescription: 'Kind-hearted, nurturing, and empathetic.',
        value: 'caring',
        icon: caringIcon,
      },
      {
        displayName: 'Dominant',
        displayDescription: 'Assertive, controlling, and commanding.',
        value: 'dominant',
        icon: crownIcon,
      },
      {
        displayName: 'Submissive',
        displayDescription: 'Obedient, yielding, and happy to follow.',
        value: 'submissive',
        icon: submissiveIcon,
      },
      {
        displayName: 'Lover',
        displayDescription:
          'Romantic, affectionate, and cherishes emotional connection.',
        value: 'lover',
        icon: heartsIcon,
      },
      {
        displayName: 'Protector',
        displayDescription: 'Strong, vigilant, and fiercely loyal.',
        value: 'protector',
        icon: protectiveIcon,
      },
      {
        displayName: 'Bad Boy',
        displayDescription: 'Rebellious, daring, and charismatic.',
        value: 'bad boy',
        icon: badBoyIcon,
      },
    ],
  },
  {
    displayName: 'Accent',
    modifies: 'voiceId',
    columns: 3,
    type: 'voice-radio',
    options: [
      {
        displayName: 'American 1',
        value: 'male-us',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 18,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us.mp3', true),
      },
      {
        displayName: 'American 2',
        value: 'male-us-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us-young.mp', true),
      },
      {
        displayName: 'American 2',
        value: 'male-us-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagUsIcon,
        preview: cdnFile('model-creator/male/voices/us-old.mp3', true),
      },
      {
        displayName: 'British',
        value: 'male-gb-young',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 0,
        endAge: 34,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/male/voices/gb-young.mp3', true),
      },
      {
        displayName: 'British',
        value: 'male-gb-old',
        ethnicities: ['white', 'brown', 'black'],
        startAge: 35,
        endAge: 80,
        icon: flagGbIcon,
        preview: cdnFile('model-creator/male/voices/gb-old.mp3', true),
      },
      {
        displayName: 'Australian',
        value: 'male-au-young',
        ethnicities: ['white'],
        startAge: 0,
        endAge: 34,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/male/voices/au-young.mp3', true),
      },
      {
        displayName: 'Australian',
        value: 'male-au-old',
        ethnicities: ['white'],
        startAge: 35,
        endAge: 80,
        icon: flagAuIcon,
        preview: cdnFile('model-creator/male/voices/au-old.mp3', true),
      },
      {
        displayName: 'Indian',
        value: 'male-id-young',
        ethnicities: ['brown'],
        startAge: 0,
        endAge: 34,
        icon: flagInIcon,
        preview: cdnFile('model-creator/male/voices/in-young.mp3', true),
      },
      {
        displayName: 'Indian',
        value: 'male-id-old',
        ethnicities: ['brown'],
        startAge: 35,
        endAge: 80,
        icon: flagInIcon,
        preview: cdnFile('model-creator/male/voices/in-old.mp3', true),
      },
      {
        displayName: 'African',
        value: 'male-africa-young',
        ethnicities: ['black'],
        startAge: 0,
        endAge: 34,
        icon: flagAfricaIcon,
        preview: cdnFile('model-creator/male/voices/africa-young.mp3', true),
      },
      {
        displayName: 'African',
        value: 'male-africa-old',
        ethnicities: ['black'],
        startAge: 35,
        endAge: 80,
        icon: flagAfricaIcon,
        preview: cdnFile('model-creator/male/voices/africa-old.mp3', true),
      },
    ],
  },
  {
    displayName: 'Occupation',
    modifies: 'occupation',
    columns: 3,
    type: 'custom-icon-radio',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Dentist',
        value: 'dentist',
        icon: toothIcon,
      },
      {
        displayName: 'Librarian',
        value: 'librarian',
        icon: bookOpenIcon,
      },
      {
        displayName: 'Designer',
        value: 'designer',
        icon: brushIcon,
      },
      {
        displayName: 'Stylist',
        value: 'stylist',
        icon: starIcon,
      },
      {
        displayName: 'Scientist',
        value: 'scientist',
        icon: atomIcon,
      },
      {
        displayName: 'Writer',
        value: 'writer',
        icon: pencilLineIcon,
      },
      {
        displayName: 'Teacher',
        value: 'teacher',
        icon: glassesIcon,
      },
      {
        displayName: 'Firefighter',
        value: 'firefighter',
        icon: flameIcon,
      },
      {
        displayName: 'Lawyer',
        value: 'lawyer',
        icon: scaleIcon,
      },
    ],
  },
  {
    displayName: 'Hobbies',
    modifies: 'hobbies',
    columns: 3,
    type: 'custom-icon-select',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
    allowEmpty: true,
    options: [
      {
        displayName: 'Cycling',
        value: 'cycling',
        icon: scaleIcon,
      },
      {
        displayName: 'Blogging',
        value: 'blogging',
        icon: blogIcon,
      },
      {
        displayName: 'Hiking',
        value: 'hiking',
        icon: mountainIcon,
      },
      {
        displayName: 'Gaming',
        value: 'gaming',
        icon: gamingPadIcon,
      },
      {
        displayName: 'Partying',
        value: 'partying',
        icon: baloonsIcon,
      },
      {
        displayName: 'Photography',
        value: 'photography',
        icon: cameraIcon,
      },
      {
        displayName: 'Volunteering',
        value: 'volunteering',
        icon: handsIcon,
      },
      {
        displayName: 'Cars',
        value: 'cars',
        icon: carIcon,
      },
      {
        displayName: 'Theater',
        value: 'theater',
        icon: masksIcon,
      },
    ],
  },
  {
    displayName: 'Man Name',
    displayTitle: 'Name Your Man',
    modifies: 'name',
    type: 'custom',
    performBannedCheck: true,
    performCelebrityCheck: true,
    max: 20,
  },
]
