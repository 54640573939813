function escapeHtml(str) {
  return str.replace(/[&<>"']/g, (match) => {
    switch (match) {
      case '&':
        return '&amp;'
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '"':
        return '&quot;'
      case "'":
        return '&#39;'
    }
  })
}

export function revertHtml(str) {
  return str.replace(/&(amp|lt|gt|quot|#39);/g, (match) => {
    switch (match) {
      case '&amp;':
        return '&'
      case '&lt;':
        return '<'
      case '&gt;':
        return '>'
      case '&quot;':
        return '"'
      case '&#39;':
        return "'"
    }
  })
}

export default escapeHtml
