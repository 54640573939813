import { inject } from 'vue'
import { useToast } from 'vue-toast-notification'

export default function useChat() {
  const $axios = inject('axios')
  const $toast = useToast()

  let updatedMessage
  async function regenerateMessage(message) {
    try {
      updatedMessage = await $axios
        .post('/user/regenerate-message', {
          messageId: message.id,
          modelId: message.modelId,
        })
        .then((response) => response.data.newMessage)
    } catch (e) {
      if (e.response.status === 451) {
        return
      }

      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
      throw e
    }

    return updatedMessage
  }

  return regenerateMessage
}
