<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SvgIcon from '@/components/SvgIcon.vue'

const $store = useStore()
const $router = useRouter()
const user = computed(() => $store.state.user)

function openSubscribePopup() {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: true,
  })
}

function openBuyLuna() {
  if (!user.value.subscription) {
    openSubscribePopup()
    return
  }

  $router.push('/buy-luna')
}
</script>

<template>
  <button @click="openBuyLuna">
    <div
      v-if="user"
      class="flex items-center gap-[10px] bg-[#11152D] py-[5px] pl-[9px] pr-[5px] rounded-[100px]"
      style="
        box-shadow:
          0px 0px 0px 1px #1e1a3d,
          0px 0px 10px 2px #13142e inset;
      "
    >
      <div
        class="py-1 px-1 rounded-full border border-white border-opacity-15"
        style="
          background: radial-gradient(
            148.09% 102.86% at 44.29% 100%,
            #cc47ff 0%,
            #9a5cff 100%
          );
          box-shadow:
            0px 0px 2.57px 0px #1cae6e,
            0px 0px 0px 1.01px #b552ff;
        "
      >
        <SvgIcon
          icon-id="logoicon-white"
          class="w-[22px] h-[22px]"
          alt="White logo"
        />
      </div>
      <p class="">{{ user.luna }}</p>
      <div
        class="flex justify-center items-center bg-[#141A3B] w-[34px] h-[34px] rounded-full"
      >
        <SvgIcon
          sprite="generator"
          icon-id="plus"
          class="h-[18px] w-[18px]"
          alt="Plus icon"
        />
      </div>
    </div>
  </button>
</template>
