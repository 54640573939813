<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import discounts from 'common/lib/discount'
import { useDiscountTimer } from '@/composables/useDiscountTimer'
import SvgIcon from '@/components/SvgIcon.vue'

const emit = defineEmits(['update:open'])

function close() {
  emit('update:open', false)
}

const { time, startTimer, clearTimer } = useDiscountTimer()
const discountCode = computed(() => $store.state.discountCode)

const discountRef = ref(null)
const $router = useRouter()
const $route = useRoute()
const $store = useStore()

const user = computed(() => $store.state.user)

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

if (
  !discountPopupOpen.value &&
  !user.value.subscription &&
  user.value.cardLast4
) {
  $store.commit('SET_DISCOUNT_POPUP', {
    open: true,
  })
}

function redirectToSubscribe() {
  $router.push('/subscribe')
}
function redirectToBuyLuna() {
  $router.push('/buy-luna')
}

const isBuyRoute = computed(
  () => $route.path === '/buy-luna' || $route.path === '/subscribe',
)

function handleDiscountClick() {
  if (!$store.state.user) {
    return $router.push('/login')
  }

  if (discounts[discountCode.value].type == 'Purchases') {
    user.value.subscription ? redirectToBuyLuna() : redirectToSubscribe()
  } else if (discounts[discountCode.value].type == 'Subscriptions') {
    redirectToSubscribe()
  } else {
    user.value.subscription ? redirectToBuyLuna() : redirectToSubscribe()
  }
}

function handleBuyAgain() {
  $router.push('/subscribe')
}

const brandName = import.meta.env.VITE_BRAND_NAME

onMounted(() => {
  startTimer()
})

onBeforeUnmount(() => {
  clearTimer()
})
</script>

<template>
  <div
    class="fixed top pt-[11px] lg:pt-[15px] top-0 w-full px-[13px] lg:px-[16px] bg-[#070917] z-50"
    :class="{
      hidden: isBuyRoute,
    }"
  >
    <div
      ref="discountRef"
      class="relative flex flex-col lg:flex-row justify-between items-center rounded-[15px] py-[9px] pl-[15px] pr-[11px]"
      style="
        background: radial-gradient(
          255.14% 174.74% at 38.76% 155.71%,
          #cc47ff 0%,
          #9a5cff 100%
        );
      "
    >
      <div class="hidden lg:flex gap-3 items-center">
        <SvgIcon icon-id="logoicon-white" class="w-[25px] h-[25px]" />
        <p
          v-if="user && !user.subscription && user.cardLast4 && !discountCode"
          class="font-semibold"
        >
          Your subscription has expired
        </p>
        <p v-else class="font-semibold">
          {{ brandName }} is now {{ discounts[discountCode].percentage }}% off!
        </p>
      </div>
      <div
        v-if="user && !user.subscription && user.cardLast4 && !discountCode"
        class="flex flex-col lg:flex-row items-center gap-[6px] lg:gap-[25px]"
      >
        <p class="font-bold lg:hidden">Your subscription has expired</p>
        <button
          type="button"
          class="text-[14px] py-[5px] px-[33px] cursor-pointer z-10 bg-[#0A0D1E] rounded-[10px]"
          style="
            box-shadow:
              0px 0px 0px 1px #1e1a3d,
              0px 0px 10px 2px #13142e inset;
          "
          @click="handleBuyAgain"
        >
          Buy again
        </button>
      </div>
      <div
        v-else
        class="flex flex-col lg:flex-row items-center gap-[6px] lg:gap-[25px]"
      >
        <p class="font-bold hidden lg:block">
          Offer ends in:
          {{ time.display }}
        </p>
        <p class="font-bold lg:hidden">
          {{ discounts[discountCode].percentage }}% off ends in:
          {{ time.display }}
        </p>
        <button
          type="button"
          class="text-[14px] py-[5px] px-[33px] cursor-pointer z-10 bg-[#0A0D1E] rounded-[10px]"
          style="
            box-shadow:
              0px 0px 0px 1px #1e1a3d,
              0px 0px 10px 2px #13142e inset;
          "
          @click="handleDiscountClick"
        >
          Claim Offer
        </button>
      </div>
    </div>
  </div>
</template>
