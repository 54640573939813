<script setup>
import { ref, toRefs, computed, watch } from 'vue'
import { useToast } from 'vue-toast-notification'

const props = defineProps({
  modelValue: Array,
  options: Array,
  columns: Number,
  name: String,
  displayName: String,
})

const $toast = useToast()

const emit = defineEmits(['update:modelValue'])

const custom = ref('')
const customEl = ref(null)
const customFocus = ref(false)

const { modelValue } = toRefs(props)

function toggleValue(value) {
  if (modelValue.value.includes(value)) {
    emit(
      'update:modelValue',
      modelValue.value.filter((v) => v != value),
    )
  } else {
    if (modelValue.value.length >= 2) {
      return $toast.error('You can only select up to 2 hobbies')
    }

    emit('update:modelValue', [...modelValue.value, value])
  }
}

function updateCustom() {
  custom.value = custom.value.trim()

  if (!custom.value) {
    return emit(
      'update:modelValue',
      modelValue.value.filter((v) =>
        props.options.map((o) => o.value).includes(v),
      ),
    )
  }

  const customIsIncluded =
    modelValue.value.filter(
      (v) => !props.options.map((o) => o.value).includes(v),
    )[0] || ''

  if (modelValue.value.length >= 2 && !customIsIncluded) {
    custom.value = ''
    return $toast.error('You can only select up to 2 hobbies')
  }

  if (customIsIncluded) {
    emit(
      'update:modelValue',
      modelValue.value.map((v) =>
        props.options.map((o) => o.value).includes(v) ? v : custom.value,
      ),
    )
  } else {
    emit('update:modelValue', [...modelValue.value, custom.value])
  }
}

function onCustomFocus() {
  customFocus.value = true

  custom.value = custom.value.trim()

  const customIsIncluded =
    modelValue.value.filter(
      (v) => !props.options.map((o) => o.value).includes(v),
    )[0] || ''

  if (modelValue.value.length >= 2 && !customIsIncluded) {
    customEl.value.blur()
    return $toast.error('You can only select up to 2 hobbies')
  }
}

watch(
  modelValue,
  () => {
    custom.value =
      modelValue.value.filter(
        (v) => !props.options.map((o) => o.value).includes(v),
      )[0] || ''
  },
  { immediate: true, deep: true },
)
</script>

<template>
  <div class="grid gap-5 auto-rows-fr grid-cols-2 lg:grid-cols-3">
    <div
      v-for="(option, i) of options"
      :key="i"
      class="bg-[#0A0D22] bg-opacity-50 p-[15px] lg:p-[25px] cursor-pointer rounded-[20px] border border-[#141A3D] border-opacity-70"
      :class="
        modelValue.includes(option.value) &&
        'outline outline-3 outline-offset-4 outline-purple-500'
      "
      @click="toggleValue(option.value)"
    >
      <div
        class="flex justify-center items-center h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded-xl border border-white border-opacity-15"
        style="
          background: radial-gradient(
            255.14% 174.74% at 38.76% 155.71%,
            #cc47ff 0%,
            #9a5cff 100%
          );
        "
      >
        <img
          :src="option.icon"
          :alt="`${option.displayName} ${displayName}`"
          class="w-6 h-6 no-image-select"
          draggable="false"
        />
      </div>
      <p class="lg:text-[19px] mt-5">{{ option.displayName }}</p>
    </div>
  </div>
  <!-- <div class="cursor-pointer mt-[22px] lg:mt-6 text-white">
    <input
      ref="customEl"
      v-model="custom"
      type="text"
      placeholder="Type a custom hobby..."
      class="block w-full rounded-[15px] pl-[19px] border border-[#111631] focus:outline-none placeholder:text-white/60 bg-[#0A0D1E] h-[62px] text-white ring-1 ring-inset ring-white/10"
      :class="
        custom || (customFocus && modelValue.length < 2)
          ? ' outline outline-3 outline-offset-4 outline-purple-500'
          : ''
      "
      @input="updateCustom"
      @focus="onCustomFocus"
      @blur="customFocus = false"
    />
  </div> -->
</template>
