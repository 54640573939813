<script setup>
import cdnFile from '@/helpers/cdnFile.js'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ButtonComponent from './ButtonComponent.vue'
import LunaAmount from './LunaAmount.vue'
import SvgIcon from '@/components/SvgIcon.vue'

const $store = useStore()
const $route = useRoute()

const props = defineProps({
  gender: {
    type: String,
    default: '',
  },
  style: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['switchToHentai', 'switchToMale', 'switchToFemale'])
const user = computed(() => $store.state.user)
const isHomePage = computed(() =>
  [
    '/',
    '/ai-boyfriend',
    '/ai-boyfriend/',
    '/ai-hentai',
    '/ai-hentai/',
  ].includes($route.path),
)
const isGirlfriendRoute = computed(() => ['/'].includes($route.path))
const isBoyfriendRoute = computed(() =>
  ['/ai-boyfriend', '/ai-boyfriend/'].includes($route.path),
)
const isHentaiRoute = computed(() =>
  ['/ai-hentai', '/ai-hentai/'].includes($route.path),
)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)

function toggleMobileMenu(value) {
  $store.commit('SET_MOBILE_MENU_OPEN', {
    open: value,
  })
}
</script>

<template>
  <div
    v-if="!mobileMenuOpen"
    class="w-full sticky bg-[#070917] py-4 lg:pt-[21px] pb-[12px] lg:pb-6 flex lg:items-center z-30"
    :class="discountPopupOpen ? 'top-[90px] lg:top-[64px]' : 'top-0'"
  >
    <div
      class="w-full max-w-[1450px] mx-auto px-4 xl:px-16 2xl:mx-auto flex lg:items-center flex-col-reverse lg:flex-row"
      :class="
        $slots.title || $slots.end || isHomePage
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <div
        v-if="isHomePage"
        class="p-[7px] bg-[#0e122a] border-[1px] border-[#161929] lg:gap-[6px] rounded-[15px] mt-3 lg:mt-0 hidden md:flex"
      >
        <router-link
          :to="'/'"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              isGirlfriendRoute,
          }"
          @click="emit('switchToFemale')"
          ><SvgIcon sprite="home" icon-id="female" class="h-[20px] w-[15px]" />
          Girlfriend</router-link
        >
        <router-link
          :to="'/ai-hentai'"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]': isHentaiRoute,
          }"
          @click="emit('switchToHentai')"
          ><SvgIcon sprite="home" icon-id="anime" class="h-[20px] w-[20px]" />
          Hentai</router-link
        >
        <router-link
          :to="'/ai-boyfriend'"
          class="flex-1 lg:flex-0 flex items-center justify-center gap-[10px] py-2 sm:py-[13px] px-2 sm:px-[26.5px] font-bold text-xs rounded-lg"
          :class="{
            'border border-white border-opacity-15 bg-[#2a3b5f]':
              isBoyfriendRoute,
          }"
          @click="emit('switchToMale')"
          ><SvgIcon sprite="home" icon-id="male" class="h-[20px] w-[20px]" />
          Boyfriend</router-link
        >
      </div>
      <slot name="title"></slot>
      <div class="flex gap-[10px]">
        <div class="flex items-center">
          <ButtonComponent
            variant="open"
            class="!rounded-[10px] w-[35px] h-[35px] flex justify-center items-center lg:hidden"
            @click="toggleMobileMenu(true)"
            ><SvgIcon
              sprite="home"
              icon-id="hamburger-menu-white"
              class="w-[17px] h-[17px]"
          /></ButtonComponent>
        </div>

        <div
          class="flex grow justify-between items-center lg:justify-normal lg:gap-3 gap-[10px]"
        >
          <router-link to="/" aria-label="Home" class="flex shrink"
            ><SvgIcon
              sprite="home"
              icon-id="logo"
              class="w-full max-w-[130px] aspect-[865/120] lg:hidden shrink no-image-select"
              draggable="false"
            />
          </router-link>
          <div v-if="user" class="flex gap-2">
            <LunaAmount />
            <slot name="end"></slot>
          </div>
          <div v-else class="flex gap-[11px]">
            <ButtonComponent
              variant="secondary"
              :borderVisible="false"
              to="/login"
              class="flex justify-center items-center w-[70px] h-[34px] lg:h-[50px] lg:w-[100px] text-[14px]"
              style="border-radius: 10px; background: #0e122a"
              >Login</ButtonComponent
            >
            <router-link
              to="/register"
              class="flex justify-center items-center w-[70px] h-[34px] lg:h-[50px] lg:w-[100px] text-[14px]"
              style="
                background: radial-gradient(
                  255.14% 174.74% at 38.76% 155.71%,
                  #cc47ff 0%,
                  #9a5cff 100%
                );
                border-radius: 10px;
              "
              >Register</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
