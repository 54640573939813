<
<script setup>
import AppHeader from '@/components/AppHeader.vue'
import Confirm from '@/components/Confirm.vue'
import { useMixpanel } from '@/composables/mixpanel'
import { usePaymentNotifier } from '@/composables/usePaymentNotifier'
import modelNames from '@/configs/nameSuggestions'
import * as stepsConfig from '@/configs/steps'
import trialConfig from '../../../../common/configs/trial'
import { createRandomAttributes } from '@/helpers/createRandomAttributes.js'
import { pickRandomMultiple } from '@/helpers/pickRandom.js'
import sleep from '@/helpers/sleep'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import { useHead } from '@unhead/vue'
import ed from 'edit-distance'
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue'
import { useLoading } from 'vue-loading-overlay'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { useStore } from 'vuex'
import ButtonComponent from '../../components/ButtonComponent.vue'
import CustomIconRadio from './components/CustomIconRadio.vue'
import CustomIconSelect from './components/CustomIconSelect.vue'
import IconRadio from './components/IconRadio.vue'
import ImageRadio from './components/ImageRadio.vue'
import VoiceRadio from './components/VoiceRadio.vue'

import femaleAnimeAvif from '@/assets/img/create-model/female/styles/anime.avif'
import femaleAnimeWebp from '@/assets/img/create-model/female/styles/anime.webp'
import femaleRealisticAvif from '@/assets/img/create-model/female/styles/realistic.avif'
import femaleRealisticWebp from '@/assets/img/create-model/female/styles/realistic.webp'
import maleAnimeAvif from '@/assets/img/create-model/male/styles/anime.avif'
import maleAnimeWebp from '@/assets/img/create-model/male/styles/anime.webp'
import maleRealisticAvif from '@/assets/img/create-model/male/styles/realistic.avif'
import maleRealisticWebp from '@/assets/img/create-model/male/styles/realistic.webp'
import SvgIcon from '@/components/SvgIcon.vue'

const createModelFunnelId = uuidv4()
const createModelFunnelTimeElapsed = createTimeElapsedCounter()

const mixpanel = useMixpanel()

const $router = useRouter()
const $route = useRoute()

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const isCreateGirlfriendRoute = computed(
  () => $route.path == '/create-girlfriend',
)
const isCreateBoyfriendRoute = computed(
  () => $route.path == '/create-boyfriend',
)

const headTitleFemale = `${import.meta.env.VITE_BRAND_NAME} | Create AI Girlfriend`
const headDescriptionFemale =
  'Create your Dream Girlfriend with AI. Customize Everything from Looks to Personality, and even her Voice. Immerse yourself in a world of love and romance.'
const headUrlFemale = `https://${import.meta.env.VITE_DOMAIN_NAME}/create-girlfriend`

const headTitleMale = `${import.meta.env.VITE_BRAND_NAME} | Create AI Boyfriend`
const headDescriptionMale =
  'Create your Dream Boyfriend with AI. Customize Everything from Looks to Personality, and even his Voice. Immerse yourself in a world of love and romance.'
const headUrlMale = `https://${import.meta.env.VITE_DOMAIN_NAME}/create-boyfriend`
const headImage = `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`
const headLogo = `https://${import.meta.env.VITE_DOMAIN_NAME}/logo.png`

const computedMeta = computed(() =>
  isCreateGirlfriendRoute.value
    ? {
        title: headTitleFemale,
        meta: [
          { name: 'description', content: headDescriptionFemale },

          { property: 'og:title', content: headTitleFemale },
          { property: 'og:description', content: headDescriptionFemale },
          { property: 'og:url', content: headUrlFemale },
          { property: 'og:image', content: headImage },
          { property: 'og:logo', content: headLogo },

          { name: 'twitter:title', content: headTitleFemale },
          { name: 'twitter:description', content: headDescriptionFemale },
          { name: 'twitter:url', content: headUrlFemale },
          { name: 'twitter:image', content: headImage },

          { name: 'robots', content: 'noindex' },
        ],
      }
    : isCreateBoyfriendRoute.value
      ? {
          title: headTitleMale,
          meta: [
            { name: 'description', content: headDescriptionMale },

            { property: 'og:title', content: headTitleMale },
            { property: 'og:description', content: headDescriptionMale },
            { property: 'og:url', content: headUrlMale },
            { property: 'og:image', content: headImage },
            { property: 'og:logo', content: headLogo },

            { name: 'twitter:title', content: headTitleMale },
            { name: 'twitter:description', content: headDescriptionMale },
            { name: 'twitter:url', content: headUrlMale },
            { name: 'twitter:image', content: headImage },

            { name: 'robots', content: 'noindex' },
          ],
        }
      : {
          title: `${import.meta.env.VITE_BRAND_NAME} | Create AI Character`,
          meta: [{ name: 'robots', content: 'noindex' }],
        },
)

useHead(computedMeta)

mixpanel.track(
  'create_ai_model_enter',
  {
    funnel_id: createModelFunnelId,
    gender_selected: isCreateGirlfriendRoute.value
      ? 'female'
      : isCreateBoyfriendRoute.value
        ? 'male'
        : 'none',
  },
  { source: 'previous' },
)

const actionLag = 300

const $axios = inject('axios')

const $store = useStore()

const $toast = useToast()

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
})

const celebrityNames = ref([])
const bannedKeywords = ref([])
const nameSuggestions = ref([])
const isSelectedFromRandom = ref(false)

const pendingModel = computed(() => $store.state.pendingModel)

const user = computed(() => $store.state.user)

function createEmptyModel() {
  const model = {
    random: false,
    gender: '',
    style: '',

    ethnicity: '',
    age: 0,
    eyeColor: '',

    hairStyle: '',
    hairLength: '',
    hairColor: '',

    bodyType: '',
    breastSize: '',
    buttSize: '',

    personality: null, // Hack because personality: '' is a valid value and causes adaptive to be highlighted
    customPersonality: null,
    voiceId: '',

    occupation: '',
    hobbies: [],
    name: '',
  }

  if (isCreateGirlfriendRoute.value) {
    model.gender = 'female'
  } else if (isCreateBoyfriendRoute.value) {
    model.gender = 'male'
  } else if ($route.query.gender) {
    model.gender = $route.query.gender
  }

  if ($route.query.style) {
    model.style = $route.query.style
  }

  return model
}

const model = ref(
  isCreateGirlfriendRoute.value || isCreateBoyfriendRoute.value
    ? createEmptyModel()
    : Object.assign(createEmptyModel(), pendingModel.value),
)

async function submitModel() {
  model.value.name =
    model.value.name[0].toLocaleUpperCase() + model.value.name.slice(1)
  model.value.name = model.value.name
    .split(' ')
    .filter((a) => a)
    .join(' ')

  $store.commit('SET_PENDING_MODEL', model.value)
}

const selectedSteps = computed(() => {
  if (!model.value.gender) return null
  if (model.value.style == 'anime') {
    if (model.value.gender == 'female') return stepsConfig.stepsFemaleAnime
    return stepsConfig.stepsMaleAnime
  }
  if (model.value.gender == 'female') return stepsConfig.stepsFemaleRealistic
  return stepsConfig.stepsMaleRealistic
})

const voices = computed(() => {
  const voiceStep = selectedSteps.value.find(
    (step) => step.modifies == 'voiceId',
  )

  return {
    ...voiceStep,
    options: voiceStep.options.filter(
      (voice) =>
        voice.startAge <= model.value.age &&
        voice.endAge >= model.value.age &&
        voice.ethnicities.includes(model.value.ethnicity),
    ),
  }
})

const finalN = computed(
  () =>
    (selectedSteps.value
      ? selectedSteps.value.length
      : Math.max(
          stepsConfig.stepsFemaleRealistic.length,
          stepsConfig.stepsFemaleAnime.length,
          stepsConfig.stepsMaleRealistic.length,
          stepsConfig.stepsMaleAnime.length,
        )) + 1,
)

const n = ref(
  isCreateGirlfriendRoute.value || isCreateBoyfriendRoute.value
    ? 1
    : $route.params.n,
)

// Step 0 is to select the gender. Steps [1, finalN] are taken from the selected steps (male or female steps)
const combinedSteps = computed(() => {
  const arr = []

  arr.push(...stepsConfig.stepsGender)

  if (selectedSteps.value) {
    arr.push(...selectedSteps.value)
  }

  return arr
})

function hasError(n) {
  const insert = function (node) {
    return 1
  }
  const remove = function (node) {
    return 1
  }
  const update = function (stringA, stringB) {
    return stringA !== stringB ? 1 : 0
  }

  const step = combinedSteps.value[n]

  const stepValue =
    model.value[step.modifies] ?? model.value[step.customModifies]
  const stepFilled = Array.isArray(stepValue) ? stepValue.length > 0 : stepValue
  if (!stepFilled && !step.allowEmpty) {
    return {
      step,
      message: `Please select ${step.displayName}`,
    }
  }
  if (stepFilled == null && !step.allowNull) {
    return {
      step,
      message: `Please select ${step.displayName}`,
    }
  }
  if (step.performBannedCheck) {
    const targetStrings = (
      Array.isArray(stepValue) ? stepValue : [stepValue]
    ).flatMap((item) => item.split(' '))
    for (let i = 0; i < targetStrings.length; i++) {
      const targetString = targetStrings[i]
      const matchingBanned = bannedKeywords.value.reduce(
        (matchingKeyword, keyword) =>
          matchingKeyword ||
          (ed.levenshtein(targetString, keyword, insert, remove, update)
            .distance <= 1
            ? keyword
            : null),
        null,
      )
      if (matchingBanned) {
        return {
          step,
          message: `${step.displayName} cannot be similar to "${matchingBanned}"`,
        }
      }
    }
  }
  if (step.performCelebrityCheck) {
    const targetString = Array.isArray(stepValue)
      ? stepValue.map((item) => item.toLowerCase()).join(' ')
      : stepValue.toLowerCase()
    for (let i = 0; i < celebrityNames.value.length; i++) {
      if (
        celebrityNames.value[i].reduce(
          (matching, name) => matching && targetString.includes(name),
          true,
        )
      ) {
        return {
          step,
          message: `${step.displayName} cannot be similar to a celebrity (${celebrityNames.value[i].map((item) => (item[0]?.toLocaleUpperCase() || '') + item.slice(1)).join(' ')})`,
        }
      }
    }
  }
  if (
    step.max &&
    (Array.isArray(stepValue)
      ? stepValue.reduce(
          (overStatus, item) => overStatus || item.length > step.max,
          false,
        )
      : stepValue.length > step.max)
  ) {
    return {
      step,
      message: `${step.displayName} should be less than ${step.max} characters`,
    }
  }
  if (step.modifies == 'name' && model.value.name.length > 30) {
    return { step, message: 'Name should be less than 30 characters' }
  }

  return false
}

async function loadBannedKeywords() {
  const bk = await import('@/configs/bannedKeywords')
  celebrityNames.value = bk.celebrityNames
  bannedKeywords.value = bk.bannedKeywords
}

async function loadNameSuggestions() {
  nameSuggestions.value = pickRandomMultiple(
    model.value.style === 'anime'
      ? modelNames[model.value.gender]['asian']
      : modelNames[model.value.gender][model.value.ethnicity],
    9,
  )
}

const fromRoute =
  !$route.query.from || $route.query.from == 'home'
    ? '/'
    : `/${$route.query.from}`

onMounted(() => {
  nextTick(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  })
})

async function viewModel() {
  if (user.value && user.value.subscription && user.value.luna < 20) {
    $store.commit('SET_NO_BALANCE_POPUP', {
      open: true,
    })
    return
  }

  if (
    (user.value &&
      (user.value.trialExpired ||
        (!user.value.subscription &&
          user.value.totalCustomModelCount >= trialConfig.customModelCap))) ||
    (user.value && !user.value.subscription && user.value.cardLast4)
  ) {
    return openSubscribePopup()
  }

  await submitModel()

  mixpanel.track('create_model_created_start', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    model_metadata: model.value,
    random: model.value.random,
  })

  return $router.push(
    '/view-model' +
      '?' +
      queryString.stringify({
        from: $route.query.from,
        funnel_id: createModelFunnelId,
        funnel_time_elapsed: createModelFunnelTimeElapsed(),
      }),
  )
}

const nextGuard = ref(false)

async function next({ instant, skip }) {
  if (nextGuard.value) return
  nextGuard.value = true

  if (n.value === 1) {
    isSelectedFromRandom.value = false
  }

  const step = combinedSteps.value[n.value]

  if (step.modifies === 'name' && model.value.name) {
    const loader = $loading.show()
    try {
      const response = await $axios.post('/user/prohibited-content-check', {
        name: model.value.name,
      })
      if (response.data.isProhibited) {
        $toast.error('Celebrity names are not allowed')
        nextGuard.value = false
        return
      }
    } catch (error) {
      nextGuard.value = false
      return
    } finally {
      loader.hide()
    }
  }

  if (skip) {
    model.value[step.modifies] = Array.isArray(model.value[step.modifies])
      ? []
      : ''

    mixpanel.track(`create_model_pick_${step.modifies}`, {
      funnel_id: createModelFunnelId,
      funnel_time_elapsed: createModelFunnelTimeElapsed(),
      model_metadata: model.value,
      skip: true,
    })
  } else {
    let isCustom = false

    const stepValueModified =
      model.value[step.modifies] || model.value[step.customModifies]

    if (step.isCustom) {
      if (Array.isArray(stepValueModified)) {
        isCustom = stepValueModified.some(
          (item) => !step.options.some((option) => option.value === item),
        )
      } else {
        isCustom = !step.options.some(
          (option) => option.value === model.value[step.modifies],
        )
      }
    }

    mixpanel.track(`create_model_pick_${step.modifies}`, {
      funnel_id: createModelFunnelId,
      funnel_time_elapsed: createModelFunnelTimeElapsed(),
      model_metadata: model.value,
      value: stepValueModified,
      is_custom: isCustom,
      skip: false,
    })
  }

  const error = hasError(n.value)
  if (error) {
    nextGuard.value = false
    return $toast.error(error.message)
  }

  // Reset next steps if just selected gender
  if (n.value == 0) {
    model.value = Object.assign(createEmptyModel(), {
      gender: model.value.gender,
    })
  }

  $store.commit('SET_PENDING_MODEL', model.value)

  if (!instant) await sleep(actionLag)

  if (n.value == finalN.value - 1) {
    nextGuard.value = false
    return await viewModel()
  }

  n.value++

  nextTick(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  })

  nextGuard.value = false
}

function prevBtn() {
  const stepModifies = combinedSteps.value[n.value].modifies

  mixpanel.track(`create_model_go_back`, {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    model_metadata: model.value,
    step: stepModifies,
  })

  if (
    n.value == 0 ||
    isCreateGirlfriendRoute.value ||
    isCreateBoyfriendRoute.value
  ) {
    $store.commit('RESET_PENDING_MODEL')

    if ($route.path == '/create-boyfriend') {
      $router.push('/ai-boyfriend')
    } else if (fromRoute) {
      $router.push(fromRoute)
    }
  } else {
    n.value--
    $router.push({
      path: `/create-model/${n.value}`,
      query: $route.query,
    })
  }
}

let trueN = Math.min(n.value, combinedSteps.value.length)
for (let i = trueN - 1; i >= 0; i--) {
  if (hasError(i)) trueN = i
}
if (trueN < n.value) {
  n.value = trueN
}

watch(
  n,
  async (newVal, oldVal) => {
    const stepModifies = combinedSteps.value[n.value].modifies

    if (stepModifies == 'occupation') {
      await loadBannedKeywords()
    }

    if (stepModifies == 'name') {
      await loadNameSuggestions()
    }

    // We don't want to change the route to /create-model if we land on /create-girlfriend or /create-boyfriend
    if (
      n.value == 1 &&
      (isCreateGirlfriendRoute.value || isCreateBoyfriendRoute.value)
    ) {
      return
    }

    const newPath = `/create-model/${n.value}`

    if ($route.fullPath !== newPath) {
      $router
        .push({
          path: newPath,
          query: $route.query,
        })
        .then(() => $store.commit('UPDATE_LAST_APP_PAGE'))
    }
  },
  { immediate: true },
)

$store.commit('UPDATE_LAST_APP_PAGE')

watch(
  () => $route.params,
  (newParams) => {
    if (newParams.n) {
      n.value = Number(newParams.n)
    }

    if (isCreateGirlfriendRoute.value) {
      model.value.gender = 'female'
      n.value = 1
    } else if (isCreateBoyfriendRoute.value) {
      model.value.gender = 'male'
      n.value = 1
    }
  },
  { deep: true },
)

function openSubscribePopup() {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: true,
    text: `create more ${model.value.gender == 'female' ? 'girls' : 'men'}`,
    freeLimitHit: 'create_model',
  })
}

function openBuyLuna() {
  mixpanel.track('create_model_buy_luna_click', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    luna_balance: user.value.luna,
  })

  $store.commit('SET_BUY_LUNA_POPUP', {
    open: true,
  })
}
usePaymentNotifier(openSubscribePopup, openBuyLuna)

const feelingLuckyOptions = {
  female: [
    {
      image: {
        avif: femaleRealisticAvif,
        webp: femaleRealisticWebp,
      },
      value: 'random-realistic',
      displayName: 'Realistic',
    },
    {
      image: {
        avif: femaleAnimeAvif,
        webp: femaleAnimeWebp,
      },
      value: 'random-anime',
      displayName: 'Anime',
    },
  ],
  male: [
    {
      image: {
        avif: maleRealisticAvif,
        webp: maleRealisticWebp,
      },
      value: 'random-realistic',
      displayName: 'Realistic',
    },
    {
      image: {
        avif: maleAnimeAvif,
        webp: maleAnimeWebp,
      },
      value: 'random-anime',
      displayName: 'Anime',
    },
  ],
}

const feelingLuckyAttributes = {
  'female-realistic': {
    gender: 'female',
    style: 'realistic',
  },
  'female-anime': {
    gender: 'female',
    style: 'anime',
  },
  'male-realistic': {
    gender: 'male',
    style: 'realistic',
  },
  'male-anime': {
    gender: 'male',
    style: 'anime',
  },
}

const randomConfirmOpen = ref(false)

function updateRandomConfirmOpen(val) {
  randomConfirmOpen.value = val

  if (!val) {
    model.value = Object.assign(createEmptyModel(), {
      gender: pendingModel.value.gender,
    })
  }
}

async function selectRandom(definedAttributes) {
  isSelectedFromRandom.value = true

  mixpanel.track(
    'create_model_random_model_click',
    {
      funnel_id: createModelFunnelId,
      funnel_time_elapsed: createModelFunnelTimeElapsed(),
      gender: definedAttributes.gender,
      style: definedAttributes.style,
    },
    { source: 'previous' },
  )

  model.value = {
    random: true,
    ...definedAttributes,
  }

  // nextTick is used so that definedAttributes (gender, style)
  // will be updated by the time we call createRandomAttributes
  nextTick(async () => {
    model.value = Object.assign(
      model.value,
      createRandomAttributes(
        definedAttributes.gender,
        definedAttributes.style,
        Object.keys(definedAttributes),
      ),
    )

    await submitModel()

    randomConfirmOpen.value = true
  })
}

function pickSuggestedName(name) {
  model.value.name = name
  mixpanel.track('create_model_pick_suggested_name', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    model_metadata: model.value,
    value: name,
  })
}

function confirmCreateRandomModel(definedAttributes) {
  mixpanel.track('create_model_random_model_confirm', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    gender: definedAttributes.gender,
    style: definedAttributes.style,
  })

  viewModel()
}

const nameRegex = /^[a-zA-Z\s]*$/

function validateName(event, step) {
  const value = event.target.value

  if (!nameRegex.test(value)) {
    model.value[step.modifies] = value.replace(/[^a-zA-Z\s]/g, '')
    event.preventDefault()
  }
}

function sanitizePaste(event, step) {
  event.preventDefault()

  const pastedText = (event.clipboardData || window.clipboardData).getData(
    'text',
  )

  const sanitizedText = pastedText.replace(/[^a-zA-Z\s]/g, '')

  const start = event.target.selectionStart
  const end = event.target.selectionEnd

  event.target.value =
    event.target.value.slice(0, start) +
    sanitizedText +
    event.target.value.slice(end)

  model[step.modifies] = event.target.value

  event.target.setSelectionRange(
    start + sanitizedText.length,
    start + sanitizedText.length,
  )
}
</script>

<template>
  <div class="lg:ml-[280px] pb-[117px] lg:pb-0">
    <AppHeader />
    <div
      class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]"
      :class="discountPopupOpen ? 'mt-[90px] lg:mt-[64px]' : ''"
    >
      <div>
        <Confirm
          :open="randomConfirmOpen"
          type="confirm"
          popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
          :button-text="{
            confirm: !user?.subscription ? 'Create' : 'Create for 20 Luna',
          }"
          @confirm="
            confirmCreateRandomModel(
              feelingLuckyAttributes[
                `${isCreateGirlfriendRoute || model.gender === 'female' ? 'female' : 'male'}-${model.style}`
              ],
            )
          "
          @update:open="updateRandomConfirmOpen"
        >
          <div>
            <h2 class="text-center">
              You will create a random {{ model.style }} {{ model.gender }}.
            </h2>
          </div>
        </Confirm>

        <template v-for="(step, i) of combinedSteps" :key="i">
          <div
            v-if="n === 0 && n === i"
            class="relative flex justify-between items-center flex-col gap-[23px] lg:gap-0 lg:flex-row w-full bg-[#0A0D22] py-[13px] lg:pl-[30px] lg:pr-[10px] mb-[20px] lg:mb-[36px] lg:mb-[26px] rounded-[20px] border border-[#141A3D] border-opacity-70"
          >
            <div class="flex items-center gap-[10px]">
              <p class="text-[18px] lg:text-[22px]">
                {{ n }} /
                <span class="text-white text-opacity-40">{{
                  combinedSteps.length - 1
                }}</span>
              </p>
              <div
                class="bg-[#CC47FF] h-[10px] w-[10px] rounded-full"
                style="box-shadow: 0px 0px 0px 3px #cc47ff33"
              ></div>
              <p class="text-[18px] lg:text-[22px]">Select Gender</p>
            </div>
            <button
              class="lg:hidden absolute top-1/2 -translate-y-1/2 left-3"
              @click="prevBtn"
            >
              <SvgIcon icon-id="chevron-left" class="w-8 h-8" />
            </button>
            <div class="hidden lg:flex gap-[10px]">
              <ButtonComponent
                variant="secondary"
                :borderVisible="false"
                @click="prevBtn"
                class="flex gap-[10px] py-[15px] pl-5 pr-[30px]"
              >
                <SvgIcon icon-id="arrow-left" class="h-6 w-6" />
                Back
              </ButtonComponent>
            </div>
          </div>
          <div v-if="n == i" class="lg:pb-8">
            <div
              v-if="n > 0"
              class="relative flex justify-between items-center flex-col gap-[23px] lg:gap-0 lg:flex-row w-full bg-[#0A0D22] py-[13px] lg:pl-[30px] lg:pr-[10px] mb-[20px] lg:mb-[36px] lg:mb-[26px] rounded-[20px] border border-[#141A3D] border-opacity-70"
            >
              <div class="flex items-center gap-[10px]">
                <p class="text-[18px] lg:text-[22px]">
                  {{ n }} /
                  <span class="text-white text-opacity-50">{{
                    combinedSteps.length - 1
                  }}</span>
                </p>
                <div
                  class="bg-[#CC47FF] h-[10px] w-[10px] rounded-full"
                  style="box-shadow: 0px 0px 0px 3px #cc47ff33"
                ></div>
                <p class="text-[18px] lg:text-[22px]">
                  {{
                    step.displayTitle
                      ? step.displayTitle
                      : `Select ${step.displayName}`
                  }}
                </p>
              </div>
              <!-- Back buttons-->
              <template v-if="n > 0 || (n == 0 && user)">
                <button
                  aria-label="Back"
                  class="lg:hidden absolute top-1/2 -translate-y-1/2 left-3"
                  @click="prevBtn"
                >
                  <SvgIcon icon-id="chevron-left" class="w-10 h-10" />
                </button>
                <div class="hidden lg:flex gap-[10px]">
                  <ButtonComponent
                    variant="secondary"
                    :borderVisible="false"
                    ariaLabel="Back"
                    @click="prevBtn"
                    class="flex gap-[10px] py-[15px] pl-5 pr-[30px]"
                  >
                    <SvgIcon icon-id="arrow-left" class="w-6 h-6" />
                    Back
                  </ButtonComponent>
                </div>
              </template>
            </div>
            <template v-if="i > 0">
              <div
                v-if="n === 1"
                class="flex flex-col lg:flex-row gap-[36px] lg:gap-[75px]"
              >
                <div
                  class="flex flex-col gap-[26px] flex-1"
                  :class="
                    !user ||
                    (user &&
                      user.models.filter((model) => model.premadeId == null)
                        ?.length === 0)
                      ? 'lg:max-w-[50%]'
                      : ''
                  "
                >
                  <div
                    v-if="
                      user &&
                      user.models.filter((model) => model.premadeId == null)
                        ?.length >= 1
                    "
                    class="hidden lg:flex bg-[#0A0D22] pl-8 lg:pl-10 min-h-[157px] flex-1 items-center rounded-[20px] border border-[#141A3D]"
                  >
                    <div class="w-[218px]">
                      <p class="text-[25px] leading-[33px] mb-[18px] text-wrap">
                        Create Custom AI
                        {{
                          isCreateGirlfriendRoute || model.gender === 'female'
                            ? 'Girlfriend'
                            : 'Boyfriend'
                        }}
                      </p>
                      <p class="text-[14px] text-[#B1B5DB] leading-[22px]">
                        Create an AI
                        {{
                          isCreateGirlfriendRoute || model.gender === 'female'
                            ? 'Girlfriend'
                            : 'Boyfriend'
                        }}
                        and choose what you prefer.
                      </p>
                    </div>
                  </div>
                  <ImageRadio
                    v-model="model[step.modifies]"
                    :display-name="step.displayName"
                    :options="step.options"
                    :columns="2"
                    :name="step.modifies"
                    :gender="model.gender"
                    :isSelectedFromRandom="isSelectedFromRandom"
                    @update:model-value="next({ instant: false })"
                  />
                </div>
                <div
                  v-if="
                    user &&
                    user.models.filter((model) => model.premadeId == null)
                      ?.length >= 1
                  "
                  class="flex flex-col gap-[26px] flex-1"
                >
                  <div
                    class="bg-[#0A0D22] pl-10 py-[45px] flex items-center rounded-[20px] border border-[#141A3D]"
                  >
                    <div class="w-[218px]">
                      <p class="text-[25px] leading-[33px] mb-[18px]">
                        Create Random AI
                        {{
                          isCreateGirlfriendRoute || model.gender === 'female'
                            ? 'Girlfriend'
                            : 'Boyfriend'
                        }}
                      </p>
                      <p class="text-[14px] text-[#B1B5DB] leading-[22px]">
                        Feeling Lucky? Let fate decide what AI
                        {{
                          isCreateGirlfriendRoute || model.gender === 'female'
                            ? 'Girlfriend'
                            : 'Boyfriend'
                        }}
                        fits you.
                      </p>
                    </div>
                  </div>
                  <ImageRadio
                    v-model="model[step.modifies]"
                    :display-name="
                      isCreateGirlfriendRoute
                        ? 'Random Female'
                        : 'Create Random AI Boyfriend'
                    "
                    :options="
                      feelingLuckyOptions[
                        isCreateGirlfriendRoute || model.gender === 'female'
                          ? 'female'
                          : 'male'
                      ]
                    "
                    :columns="2"
                    :isSelectedFromRandom="isSelectedFromRandom"
                    name="random"
                    @update:model-value="
                      (v) =>
                        selectRandom(
                          feelingLuckyAttributes[
                            `${isCreateGirlfriendRoute || model.gender === 'female' ? 'female' : 'male'}-${v.split('-')[1]}`
                          ],
                        )
                    "
                  >
                    <div
                      class="absolute right-0 bottom-0 bg-[#070917] p-2 md:p-3 rounded-tl-xl"
                    >
                      <SvgIcon icon-id="dice" class="w-8 h-8" />
                    </div>
                  </ImageRadio>
                </div>
              </div>
            </template>
            <template v-if="step.type == 'image-radio' && n !== 1">
              <ImageRadio
                v-model="model[step.modifies]"
                :display-name="step.displayName"
                :options="step.options"
                :columns="step.columns"
                :name="step.modifies"
                :gender="model.gender"
                @update:model-value="next({ instant: false })"
              />
            </template>
            <template v-else-if="step.type == 'icon-radio'">
              <IconRadio
                v-model:modelValue="model[step.modifies]"
                v-model:customValue="model[step.customModifies]"
                :isCustom="step.isCustom"
                :display-name="step.displayName"
                :options="step.options"
                :columns="step.columns"
                :name="step.modifies"
              />
              <ButtonComponent
                :dropShadow="false"
                class="w-full mx-auto mt-10 px-3.5 py-[14px] text-sm font-semibold rounded-[15px]"
                @click="() => next({ instant: true })"
              >
                Next
              </ButtonComponent>
            </template>
            <template v-else-if="step.type == 'voice-radio'">
              <VoiceRadio
                v-model="model[voices.modifies]"
                :display-name="voices.displayName"
                :options="voices.options"
                :columns="voices.columns"
                :name="voices.modifies"
              />
              <ButtonComponent
                :dropShadow="false"
                class="w-full mx-auto mt-10 px-3.5 py-[14px] text-sm font-semibold rounded-[15px]"
                @click="() => next({ instant: true })"
              >
                Next
              </ButtonComponent>
            </template>
            <template v-else-if="step.type == 'custom-icon-radio'">
              <CustomIconRadio
                v-model="model[step.modifies]"
                :display-name="step.displayName"
                :options="step.options"
                :columns="step.columns"
                :name="step.modifies"
              />
              <ButtonComponent
                :dropShadow="false"
                class="w-full mx-auto mt-10 px-3.5 py-[14px] text-sm font-semibold rounded-[15px]"
                @click="() => next({ instant: true })"
              >
                Next
              </ButtonComponent>
              <button
                v-if="step.allowEmpty"
                class="text-purple-500 mx-auto block text-center py-2 mt-2 text-sm"
                @click="() => next({ instant: true, skip: true })"
              >
                Skip
              </button>
            </template>
            <template v-else-if="step.type == 'custom-icon-select'">
              <CustomIconSelect
                v-model="model[step.modifies]"
                :display-name="step.displayName"
                :options="step.options"
                :columns="step.columns"
                :name="step.modifies"
              />
              <ButtonComponent
                :dropShadow="false"
                class="w-full mx-auto mt-10 py-[14px] text-sm font-semibold rounded-[15px]"
                @click="() => next({ instant: true })"
              >
                Next
              </ButtonComponent>
              <button
                v-if="step.allowEmpty"
                class="text-purple-500 mx-auto block text-center py-2 mt-2 text-sm"
                @click="() => next({ instant: true, skip: true })"
              >
                Skip
              </button>
            </template>
            <template v-else>
              <div v-if="n !== 1">
                <input
                  v-model="model[step.modifies]"
                  :name="step.modifies"
                  @input="validateName($event, step)"
                  @paste="sanitizePaste($event, step)"
                  class="mt-[26px] h-[65px] pl-5 block w-full rounded-[15px] border border-[#111631] focus:outline-none placeholder:text-white/60 bg-[#0A0D1E] text-white"
                  :placeholder="step.displayName"
                />
                <div class="mt-[31px] lg:mt-[43px] flex flex-col gap-5">
                  <p>Name Suggestions:</p>
                  <div
                    class="grid grid-cols-3 lg:flex gap-[9px] overflow-x-scroll hide-scrollbar"
                  >
                    <div
                      v-for="name of nameSuggestions"
                      :key="name"
                      class="cursor-pointer bg-[#0A0D1E] h-[55px] flex justify-center items-center px-10 rounded-[10px] border border-[#111631]"
                      :style="
                        model.name === name
                          ? {
                              background:
                                'radial-gradient(255.14% 174.74% at 38.76% 155.71%, #CC47FF 0%, #9A5CFF 100%)',
                            }
                          : ''
                      "
                      :class="
                        model.name === name
                          ? 'bg-[#CC47FF] border border-white border-opacity-15'
                          : ''
                      "
                      @click="pickSuggestedName(name)"
                    >
                      <p class="text-[14px]">{{ name }}</p>
                    </div>
                  </div>
                </div>
                <ButtonComponent
                  :dropShadow="false"
                  class="flex justify-center gap-[15px] items-center w-full lg:w-[296px] mx-auto mt-[31px] lg:mt-[47px] py-[14px]"
                  @click="() => next({ instant: true })"
                >
                  {{ !user?.subscription ? 'Create' : 'Create for 20 Luna' }}
                  <SvgIcon icon-id="arrow-right" class="w-6 h-6" />
                </ButtonComponent>
              </div>
            </template>

            <p v-if="step.sidenote" class="mt-10 italic text-sm">
              * {{ step.sidenote }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.max-w-wizard {
  width: 100%;
  max-width: 100%;
}

@media screen and (min-width: 1024px) {
  .max-w-wizard {
    max-width: 1273px;
  }
}

/* @media (min-width: 768px) {
  .max-w-wizard {
    max-width: min(48rem, 60vw);
    
  }
} */
</style>
>
