<script setup>
import { ref, inject, computed, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import googleAuth from '@/services/google-auth'
import twitterAuth from '@/services/twitter-auth'
import { useHead } from '@unhead/vue'
import ButtonComponent from '../../components/ButtonComponent.vue'
import { useMixpanel } from '@/composables/mixpanel'
import ErrorAlert from '@/components/ErrorAlert.vue'
import SvgIcon from '@/components/SvgIcon.vue'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Login`,
  meta: [
    {
      name: 'description',
      content:
        'Log in to access your account and explore our app. Secure and convenient login for all users.',
    },
  ],
})

const mixpanel = useMixpanel()

mixpanel.track('login_page_visit', {}, { source: 'previous' })

const $axios = inject('axios')
const $store = useStore()
const $router = useRouter()

const email = ref($store.state.email)
const password = ref($store.state.password)

const error = ref(null)

watch(email, (newEmail) => {
  $store.commit('SET_EMAIL', newEmail)
})

watch(password, (newPassword) => {
  $store.commit('SET_PASSWORD', newPassword)
})

onUnmounted(() => {
  $store.commit('SET_EMAIL', null)
  $store.commit('SET_PASSWORD', null)
})

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
})

const analytics = computed(() => $store.state.analytics)
const lastAppPage = computed(() => $store.state.lastAppPage)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

function google() {
  // Mixpanel will automatically use the Beacon API on redirect and preserve the correct event order
  mixpanel.track('login_click_google')

  window.location.href = googleAuth.getLoginUrl({
    ...analytics.value,
    redirectPath: lastAppPage.value
      ? decodeURIComponent(lastAppPage.value)
      : '/',
  })
}

function twitter() {
  // Mixpanel will automatically use the Beacon API on redirect and preserve the correct event order
  mixpanel.track('login_click_twitter')

  window.location.href = twitterAuth.getLoginUrl({
    ...analytics.value,
    redirectPath: lastAppPage.value
      ? decodeURIComponent(lastAppPage.value)
      : '',
  })
}

async function login() {
  const loader = $loading.show()
  const premadeModel = computed(() => $store.state.premadeModel)

  mixpanel.track('login_click_signin')

  $axios
    .post('/auth/signin', {
      email: email.value,
      password: password.value,
      modelIds: premadeModel.value ? [premadeModel.value.id] : [],
    })
    .then(async (res) => {
      await $store.commit('SET_USER', res.data)
      $store.commit('SET_FREE_GENERATED_IMAGES', res.data.freeGeneratedImages)
      await $store.commit('SET_PREMADE_MODEL', null)
      await $router.push(
        lastAppPage.value ? decodeURIComponent(lastAppPage.value) : '/',
      )
      return res.data
    })
    .catch((e) => {
      const message = e?.response?.data?.message
      error.value =
        message || 'Unable to perform action. Please try again later.'
    })
    .finally(() => {
      loader.hide()
    })
}
</script>

<template>
  <div class="flex min-h-full flex-1 relative">
    <SvgIcon
      sprite="main"
      icon-id="auth-particles"
      viewBox="0 0 1920 788"
      class="absolute bottom-0 left-0 w-full h-auto"
    />
    <div
      class="flex flex-1 flex-col justify-center px-2 py-3 lg:py-12 2xl:py-0 sm:px-6 lg:px-20 xl:px-24"
    >
      <div
        class="relative z-20 bg-[#0A0D22] pt-5 lg:pt-[63px] pb-[18px] lg:pb-[45px] lg:mx-auto rounded-[35px] border border-[#141A3D] border-opacity-70 px-4 lg:px-[75px] lg:w-[625px]"
        :class="discountPopupOpen ? 'mt-[115px] lg:mt-[64px]' : ''"
      >
        <div class="flex flex-col items-center gap-[18px] lg:gap-[30px]">
          <router-link to="/">
            <SvgIcon
              sprite="home"
              icon-id="logo"
              class="w-[130px] lg:w-[153px] aspect-[865/120]"
            />
          </router-link>
          <h2 class="text-[26px] lg:text-[35px] leading-[1] text-center">
            Sign in to your Account
          </h2>
          <p class="text-sm lg:text-base text-[#f4f7fb] leading-[1]">
            Don't have an account?
            <router-link to="/register" class="text-[#cc47ff]"
              >Register</router-link
            >
          </p>
        </div>

        <div class="mt-[25px] lg:mt-[30px]">
          <div>
            <form
              action="#"
              method="POST"
              class="space-y-[20px] lg:space-y-[27px]"
              @submit.prevent="login"
              @focusin="error = null"
            >
              <div>
                <label
                  for="email"
                  class="block leading-6 text-white text-opacity-80 text-sm lg:text-base"
                  >Email Address</label
                >
                <div class="mt-[5px] lg:mt-[10px]">
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required=""
                    class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block leading-6 text-white text-opacity-80 text-sm lg:text-base"
                  >Password</label
                >
                <div class="mt-[5px] lg:mt-[10px]">
                  <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    class="block w-full h-[50px] pl-[25px] bg-[#0E122A] py-[18px] text-white rounded-[15px] border border-[#111631]"
                  />
                </div>
              </div>

              <div
                class="flex lg:justify-between flex-col gap-2 lg:gap-[unset] lg:flex-row text-[14px]"
              >
                <router-link
                  to="/forgot-password"
                  class="text-white text-opacity-80"
                  >Forgot password?</router-link
                >
              </div>

              <div>
                <ButtonComponent type="submit" class="w-full py-[14px]">
                  Login
                </ButtonComponent>
              </div>
              <ErrorAlert v-if="error" :errorText="error" />
            </form>
          </div>

          <div>
            <div
              class="my-[15px] lg:my-[30px] text-[#B1B5DB] text-sm flex items-center gap-2 lg:gap-4"
            >
              <div
                class="h-[1px] flex-1"
                style="
                  background: linear-gradient(
                    270deg,
                    rgba(255, 255, 255, 0.3) 0%,
                    rgba(255, 255, 255, 0) 100%
                  );
                "
              ></div>
              <p>Or continue with</p>
              <div
                class="h-[1px] flex-1"
                style="
                  background: linear-gradient(
                    270deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.3) 100%
                  );
                "
              ></div>
            </div>
            <div class="flex gap-[9px]">
              <a
                href="#"
                class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
                @click.prevent="google"
              >
                <SvgIcon icon-id="google" class="h-6 w-6" />
                <span class="leading-6">Google</span>
              </a>
              <a
                href="#"
                class="flex justify-center items-center gap-[14px] flex-1 bg-[#0E122A] py-[15px] lg:py-[20px] rounded-[15px] border border-[#141A3D]"
                @click.prevent="twitter"
              >
                <SvgIcon icon-id="twitter" class="h-6 w-6" />
                <span class="text-sm font-semibold leading-6">Twitter</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottom-gradient {
  height: 50%;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 11.98%, #000 71.35%);
  opacity: 0.6;
  bottom: 0;
}

input:focus {
  box-shadow: 0px 0px 0px 4px #cc47ff33;
}
</style>
